import { collection, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore"
import { firestore } from "../firebaseCore"

export const collectionNews = collection(
  firestore,
  "news",
)

export function getNewsQuery(company) {
  return getDocs(query(
    collectionNews,
    where(
      "company",
      "==",
      company,
    ),
    orderBy(
      "publishDay",
      "desc",
    ),
    limit(5),
  ))
}

export function getNextNews(company, lastVisible) {
  return getDocs(query(
    collectionNews,
    where(
      "company",
      "==",
      company,
    ),
    orderBy(
      "publishDay",
      "desc",
    ),
    startAfter(lastVisible),
    limit(5),
  ))
}
