import type { DocumentReference, FieldValue } from "firebase/firestore"

export const NEWS_TYPE = {
  "INFORMATION": "information",
  "NEW": "new",
  "CONTESTS": "contests",
}

export class NewsModel {
  company: DocumentReference | null
  description: string | null
  descriptionEN: string | null
  image: string | null
  imagePath: string | null
  newsType: string | null
  newsTypeOld: string | null
  publishDay: string | null
  title: string | null
  titleEN: string | null
  accessSupplier: string | null
  createdByUser: DocumentReference | null
  lastUpdateByUser: DocumentReference | null
  lastUpdateTime: FieldValue | null
  reference: DocumentReference | null

  constructor(data, reference?) {
    this.company = data.company || null
    this.description = data.description || null
    this.descriptionEN = data.descriptionEN || null
    this.image = data.image || null
    this.imagePath = data.imagePath || null
    this.newsType = data.newsType || null
    this.newsTypeOld = data.newsTypeOld || null
    this.publishDay = data.publishDay || null
    this.title = data.title || null
    this.titleEN = data.titleEN || null
    this.accessSupplier = data.accessSupplier || null
    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null
    this.reference = reference
  }

  toMap() {
    return {
      "company": this.company,
      "description": this.description?.trim() || null,
      "descriptionEN": this.descriptionEN?.trim() || null,
      "image": this.image,
      "imagePath": this.imagePath,
      "newsType": this.newsType,
      "publishDay": this.publishDay,
      "title": this.title?.trim() || null,
      "accessSupplier": this.accessSupplier,
      "titleEN": this.titleEN?.trim() || null,

      "lastUpdateTime": this.lastUpdateTime || null,
      "lastUpdateByUser": this.lastUpdateByUser || null,
    }
  }
}

export function mapNews(newsData) {
  return new NewsModel(
    newsData.data(),
    newsData.ref,
  )
}
