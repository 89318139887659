<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import {
  mdiAccountGroup,
  mdiBell,
  mdiBook,
  mdiCalendarClock,
  mdiCartVariant,
  mdiCashRegister,
  mdiCityVariantOutline,
  mdiClipboardArrowLeft,
  mdiClipboardCheckMultipleOutline,
  mdiFileDocumentMultiple,
  mdiFinance,
  mdiFoodForkDrink,
  mdiHandshake,
  mdiLogout,
  mdiMessage,
  mdiOfficeBuilding,
  mdiOfficeBuildingMarkerOutline,
  mdiPuzzle,
  mdiRoutes,
  mdiTicketPercentOutline,
  mdiViewDashboard,
  mdiWeatherNight,
  mdiWeatherSunny,
} from "@mdi/js"
import { storeToRefs } from "pinia"
import { ROLE, isOwner } from "../../helpers/roles"
import { isSandwiczSzop } from "../../helpers/company"
import { mapDateFromFirebaseFormat } from "../../helpers/time"
import { useVuetify } from "../../useVuetify"
import i18n from "~/i18n"
import { useVuex } from "~/useVuex"
import { useDrawerStore } from "~/store/drawer"

const drawerStore = useDrawerStore()
const { visible } = storeToRefs(drawerStore)

const ModeratorPanel = [
  {
    "icon": mdiViewDashboard,
    "title": i18n.t("Navigation.admin"),
    "link": "/moderator",
  },
  {
    "icon": mdiOfficeBuilding,
    "title": i18n.t("Navigation.companies"),
    "link": "/companies",
  },
  {
    "icon": mdiMessage,
    "title": i18n.t("Navigation.messages"),
    "link": "/messages",
  },
  {
    "divider": true,
  },
  {
    "icon": mdiLogout,
    "title": i18n.t("Navigation.sign-out"),
    "link": "/sign-out",
  },
]
const ManagerPanel = [
  {
    "icon": mdiRoutes,
    "title": i18n.t("Navigation.routes"),
    "link": "/routes",
  },
  {
    "icon": mdiOfficeBuildingMarkerOutline,
    "title": i18n.t("Navigation.suppliers"),
    "link": "/suppliers",
    "subGroup": true,
    "items": [
      {
        "title": i18n.t("Navigation.search"),
        "link": "/suppliers",
      },
      {
        "title": i18n.t("Navigation.map"),
        "link": "/suppliersMap",
      },
    ],
  },
  {
    "icon": mdiFoodForkDrink,
    "title": i18n.t("Navigation.menu"),
    "link": "/menu",
  },
  {
    "icon": mdiClipboardArrowLeft,
    "title": i18n.t("Navigation.menuReturns"),
    "link": "/menuReturn",
  },
  {
    "icon": mdiCalendarClock,
    "title": i18n.t("Navigation.news"),
    "link": "/news",
  },
  {
    "icon": mdiFileDocumentMultiple,
    "title": i18n.t("Navigation.invoices"),
    "link": "/invoices",
  },
  {
    "icon": mdiOfficeBuildingMarkerOutline,
    "title": i18n.t("DeliversSellsView.title"),
    "link": "/delivers-sells",
  },
  {
    "icon": mdiCityVariantOutline,
    "title": i18n.t("Navigation.facilities"),
    "subGroup": true,
    "items": [
      {
        "icon": mdiCityVariantOutline,
        "title": i18n.t("Navigation.suppliersSubsidy"),
        "link": "/suppliersSubsidy",
      },
      {
        "icon": mdiHandshake,
        "title": i18n.t("Navigation.subsidy"),
        "link": "/subsidy",
      },
    ],
  },
  {
    "icon": mdiAccountGroup,
    "title": i18n.t("Navigation.employees"),
    "link": "/employees",
    "subGroup": true,
    "items": [
      {
        "icon": mdiAccountGroup,
        "title": i18n.t("Navigation.employees"),
        "link": "/employees",
      },
      {
        "title": i18n.t("Navigation.employeesRanking"),
        "link": "/employeesRanking",
      },
    ],
  },
  {
    "icon": mdiClipboardCheckMultipleOutline,
    "title": i18n.t("OnlineTransactionReports.title"),
    "subGroup": true,
    "items": [
      {
        "title": i18n.t("Navigation.stats"),
        "link": "/ordersStatistic",
      },
      {
        "title": i18n.t("Navigation.notyfication"),
        "link": "/stats",
      },
      {
        "title": i18n.t("Navigation.orders"),
        "link": "/orders",
      },
      {
        "title": i18n.t("OnlineTransactionReports.title2"),
        "link": "/onlineTransactionReports",
      },
      {
        "title": i18n.t("PromotionsReports.title"),
        "link": "/promotionsReports",
      },
    ],
  },
  {
    "icon": mdiTicketPercentOutline,
    "title": i18n.t("Navigation.coupons"),
    "subGroup": true,
    "items": [
      {
        "title": i18n.t("Navigation.coupons"),
        "link": "/coupons",
      },
      {
        "title": i18n.t("Navigation.couponStatistics"),
        "link": "/promo-code-statistics",
      },
      {
        "title": i18n.t("Navigation.couponsUsed"),
        "link": "/promo-code-usage",
      },
    ],
  },
  {
    "icon": mdiFinance,
    "title": i18n.t("Navigation.settlement"),
    "link": "/summary",
  },
]
const BillerPanel = [
  {
    "icon": mdiRoutes,
    "title": i18n.t("Navigation.routes"),
    "link": "/routes",
  },
  {
    "icon": mdiOfficeBuildingMarkerOutline,
    "title": i18n.t("Navigation.suppliers"),
    "link": "/suppliers",
    "subGroup": true,
    "items": [
      {
        "title": i18n.t("Navigation.search"),
        "link": "/suppliers",
      },
      {
        "title": i18n.t("Navigation.map"),
        "link": "/suppliersMap",
      },
    ],
  },
  {
    "icon": mdiAccountGroup,
    "title": i18n.t("Navigation.employees"),
    "link": "/employees",
    "subGroup": true,
    "items": [
      {
        "icon": mdiAccountGroup,
        "title": i18n.t("Navigation.employees"),
        "link": "/employees",
      },
      {
        "title": i18n.t("Navigation.employeesRanking"),
        "link": "/employeesRanking",
      },
    ],
  },
  {
    "icon": mdiOfficeBuildingMarkerOutline,
    "title": i18n.t("DeliversSellsView.title"),
    "link": "/delivers-sells",
  },
]
const GetDeliverPanel = [
  {
    "icon": mdiRoutes,
    "title": i18n.t("Navigation.routes"),
    "link": "/routes",
  },
  {
    "icon": mdiOfficeBuildingMarkerOutline,
    "title": i18n.t("Navigation.suppliers"),
    "link": "/suppliers",
    "subGroup": true,
    "items": [
      {
        "title": i18n.t("Navigation.search"),
        "link": "/suppliers",
      },
      {
        "title": i18n.t("Navigation.map"),
        "link": "/suppliersMap",
      },
    ],
  },
  {
    "icon": mdiFoodForkDrink,
    "title": i18n.t("Navigation.menu"),
    "link": "/menu",
  },
  {
    "icon": mdiClipboardArrowLeft,
    "title": i18n.t("Navigation.menuReturns"),
    "link": "/menuReturn",
  },
  {
    "icon": mdiCalendarClock,
    "title": i18n.t("Navigation.news"),
    "link": "/news",
  },
  {
    "icon": mdiFileDocumentMultiple,
    "title": i18n.t("Navigation.invoices"),
    "link": "/invoices",
  },
  {
    "icon": mdiOfficeBuildingMarkerOutline,
    "title": i18n.t("DeliversSellsView.title"),
    "link": "/delivers-sells",
  },
  {
    "icon": mdiAccountGroup,
    "title": i18n.t("Navigation.employees"),
    "link": "/employees",
    "subGroup": true,
    "items": [
      {
        "icon": mdiAccountGroup,
        "title": i18n.t("Navigation.employees"),
        "link": "/employees",
      },
      {
        "title": i18n.t("Navigation.employeesRanking"),
        "link": "/employeesRanking",
      },
    ],
  },
  {
    "icon": mdiClipboardCheckMultipleOutline,
    "title": i18n.t("OnlineTransactionReports.title"),
    "subGroup": true,
    "items": [
      {
        "title": i18n.t("Navigation.stats"),
        "link": "/ordersStatistic",
      },
      {
        "title": i18n.t("Navigation.notyfication"),
        "link": "/stats",
      },
      {
        "title": i18n.t("Navigation.orders"),
        "link": "/orders",
      },
      {
        "title": i18n.t("OnlineTransactionReports.title2"),
        "link": "/onlineTransactionReports",
      },
      {
        "title": i18n.t("PromotionsReports.title"),
        "link": "/promotionsReports",
      },
    ],
  },
  {
    "icon": mdiTicketPercentOutline,
    "title": i18n.t("Navigation.coupons"),
    "subGroup": true,
    "items": [
      {
        "title": i18n.t("Navigation.coupons"),
        "link": "/coupons",
      },
      {
        "title": i18n.t("Navigation.couponStatistics"),
        "link": "/promo-code-statistics",
      },
      {
        "title": i18n.t("Navigation.couponsUsed"),
        "link": "/promo-code-usage",
      },
    ],
  },
  {
    "icon": mdiFinance,
    "title": i18n.t("Navigation.settlement"),
    "link": "/summary",
  },
]
const DefaultPanel = [
  {
    "icon": mdiLogout,
    "title": i18n.t("Navigation.sign-out"),
    "link": "/sign-out",
  },
]
const CustomerManagerPanel = [
  {
    "icon": mdiAccountGroup,
    "title": i18n.t("Navigation.employees"),
    "link": "/subsidyBudget",
  },
  {
    "icon": mdiCityVariantOutline,
    "title": i18n.t("Navigation.suppliersSubsidy"),
    "link": "/customersSubsidy",
  },
]
const AdminPanel = [
  {
    "icon": mdiOfficeBuilding,
    "title": i18n.t("Navigation.company"),
    "link": "/company",
  },
  {
    "icon": mdiRoutes,
    "title": i18n.t("Navigation.routes"),
    "link": "/routes",
  },
  {
    "icon": mdiOfficeBuildingMarkerOutline,
    "title": i18n.t("Navigation.suppliers"),
    "subGroup": true,
    "items": [
      {
        "title": i18n.t("table.search"),
        "link": "/suppliers",
      },
      {
        "title": i18n.t("Navigation.map"),
        "link": "/suppliersMap",
      },
    ],
  },
  // {
  //   icon: mdiAccountMultiplePlus,
  //   title: i18n.t('Navigation.newUsers'),
  //   link: '/new-users',
  // },
  {
    "icon": mdiAccountGroup,
    "title": i18n.t("Navigation.employees"),
    "link": "/employees",
    "subGroup": true,
    "items": [
      {
        "icon": mdiAccountGroup,
        "title": i18n.t("Navigation.employees"),
        "link": "/employees",
      },
      {
        "title": i18n.t("Navigation.employeesRanking"),
        "link": "/employeesRanking",
      },
    ],
  },
  {
    "icon": mdiFoodForkDrink,
    "title": i18n.t("Navigation.menu"),
    "link": "/menu",
  },
  {
    "icon": mdiClipboardArrowLeft,
    "title": i18n.t("Navigation.menuReturns"),
    "link": "/menuReturn",
  },
  {
    "icon": mdiCalendarClock,
    "title": i18n.t("Navigation.news"),
    "link": "/news",
  },
  {
    "icon": mdiFileDocumentMultiple,
    "title": i18n.t("Navigation.invoices"),
    "link": "/invoices",
  },
  {
    "icon": mdiOfficeBuildingMarkerOutline,
    "title": i18n.t("DeliversSellsView.title"),
    "link": "/delivers-sells",
  },
  {
    "icon": mdiCashRegister,
    "title": i18n.t("PaymentsLastWorkingDay.title"),
    "link": "/payment-last-working-day",
  },
  {
    "icon": mdiCityVariantOutline,
    "title": i18n.t("Navigation.facilities"),
    "subGroup": true,
    "items": [
      {
        "icon": mdiCityVariantOutline,
        "title": i18n.t("Navigation.suppliersSubsidy"),
        "link": "/suppliersSubsidy",
      },
      {
        "icon": mdiHandshake,
        "title": i18n.t("Navigation.subsidy"),
        "link": "/subsidy",
      },
    ],
  },
  {
    "icon": mdiTicketPercentOutline,
    "title": i18n.t("Navigation.coupons"),
    "subGroup": true,
    "items": [
      {
        "title": i18n.t("Navigation.coupons"),
        "link": "/coupons",
      },
      {
        "title": i18n.t("Navigation.couponStatistics"),
        "link": "/promo-code-statistics",
      },
      {
        "title": i18n.t("Navigation.couponsUsed"),
        "link": "/promo-code-usage",
      },
    ],
  },
  {
    "icon": mdiClipboardCheckMultipleOutline,
    "title": i18n.t("OnlineTransactionReports.title"),
    "subGroup": true,
    "items": [
      {
        "title": i18n.t("Navigation.stats"),
        "link": "/ordersStatistic",
      },
      {
        "title": i18n.t("Navigation.notyfication"),
        "link": "/stats",
      },
      {
        "title": i18n.t("Navigation.orders"),
        "link": "/orders",
      },
      {
        "title": i18n.t("OnlineTransactionReports.title2"),
        "link": "/onlineTransactionReports",
      },
      {
        "title": i18n.t("PromotionsReports.title"),
        "link": "/promotionsReports",
      },
    ],
  },
  {
    "icon": mdiFinance,
    "title": i18n.t("Navigation.settlement"),
    "link": "/summary",
  },
]

const { getState, getAction } = useVuex()

const { setTheme } = useVuetify()

const navigationWithoutCompany = [
  {
    "icon": mdiOfficeBuilding,
    "title": i18n.t("Navigation.company"),
    "link": "/company",
  },
  { "divider": true },
  {
    "icon": mdiLogout,
    "title": i18n.t("Navigation.sign-out"),
    "link": "/sign-out",
  },
]

const isDark = ref(false)
const showAgreementDialog = ref(false)

const userData = getState("userData", "app")
const user = getState("user", "app")
const reportBugOrSuggestion = getState("reportBugOrSuggestion", "reportBugOrSuggestion")
const reportBugOrSuggestionSolved = getState("reportBugOrSuggestionSolved", "reportBugOrSuggestion")
const reportBugOrSuggestionActive = getState("reportBugOrSuggestionActive", "reportBugOrSuggestion")
const reportBugOrSuggestionWaitingCustomerAnswer = getState("reportBugOrSuggestionWaitingCustomerAnswer", "reportBugOrSuggestion")
const reportBugOrSuggestionWithoutCities = getState("reportBugOrSuggestionWithoutCities", "reportBugOrSuggestion")
const unsubscribe = getState("unsubscribe", "reportBugOrSuggestion")
const company = getState("company", "company")

const cateringOrdersToDo = getState("cateringOrdersToDo", "messages")
const unsubscribeCateringOrders1 = getState("unsubscribe1", "messages")
const unsubscribeCateringOrders2 = getState("unsubscribe2", "messages")

const reportBugOrSuggestionCmp = computed(() => [
  ...(reportBugOrSuggestion.value || []),
  ...(reportBugOrSuggestionSolved.value || []),
  ...(reportBugOrSuggestionActive.value || []),
  ...(reportBugOrSuggestionWaitingCustomerAnswer.value || []),
  ...(reportBugOrSuggestionWithoutCities.value || []),
] || [])

const isReportBugOrSuggestion = computed(() => {
  const isActiveFilter = item =>
    item?.status == "active" || item?.status == "solved"
  return (reportBugOrSuggestionCmp.value || []).filter(isActiveFilter).length
})

const isCateringOrderToDo = computed(() => {
  return (cateringOrdersToDo.value || []).length
})

const agreementText = computed(() =>
  isOwner(userData.value?.role)
    ? i18n.t("CompanyView.agreementDescriptionOwner")
    : i18n.t("CompanyView.agreementDescription"),
)

const menuItems = computed(() => {
  let additionalNav: {
    icon: string
    title: string
    subGroup?: boolean
    link?: string
    items?: {
      icon?: string
      title: string
      link: string
    }[]
    divider?: boolean
  }[] = []

  if (isSandwiczSzop(userData.value?.company?.id)) {
    additionalNav = [
      {
        "icon": mdiPuzzle,
        "title": i18n.t("OnlineTransactionReports.title3").toString(),
        "subGroup": true,
        "items": [
          {
            "icon": mdiBook,
            "title": i18n.t("MissingCompaniesView.title").toString(),
            "link": "/missingCompanies",
          },
        ],
      },
    ]
  }

  if (user.value.displayName == ROLE.CUSTOMER && userData.value.role == ROLE.MANAGER)
    return [...CustomerManagerPanel, ...additionalNav]

  switch (userData.value?.role) {
    case ROLE.OWNER:
      if (userData.value?.company)
        return [...AdminPanel, ...additionalNav]

      return navigationWithoutCompany
    case ROLE.GET_DELIVER:
      return [...GetDeliverPanel, ...additionalNav]
    case ROLE.MANAGER:
      return [...ManagerPanel, ...additionalNav]
    case ROLE.MODERATOR:
      return ModeratorPanel
    case ROLE.BILLER:
      return BillerPanel
    default:
      return DefaultPanel
  }
})

function onShowAgreementDialog() {
  if (
    company.value
    && userData.value
    && !unsubscribe.value
    && userData.value?.profile?.cities
  )
    getAction("getReportBugOrSuggestion", "reportBugOrSuggestion")()

  if (
    userData.value
    && company.value?.agreement
    && Object.values(company.value?.agreement).includes(false)
  ) {
    const timeToAcceptAgreement = mapDateFromFirebaseFormat(
      company.value?.timeToAcceptAgreement,
    )

    if (localStorage?.timeToAcceptAgreement !== timeToAcceptAgreement?.toString())
      showAgreementDialog.value = true
  }
}

function close() {
  localStorage.timeToAcceptAgreement = mapDateFromFirebaseFormat(
    company.value?.timeToAcceptAgreement,
  )

  showAgreementDialog.value = false
}

function toggleTheme2() {
  setTheme(!isDark.value)
  localStorage.dark = JSON.stringify(!isDark.value)
  isDark.value = !isDark.value
}

function getCateringOrdersData() {
  if (!unsubscribeCateringOrders1.value)
    getAction("getCateringOrdersToDo", "messages")()

  if (!unsubscribeCateringOrders2.value && company.value)
    getAction("getCompanyCateringOrders", "messages")(company.value.reference)
}
watch(company, () => {
  if (company.value) {
    onShowAgreementDialog()
    getCateringOrdersData()
  }
})

onMounted(() => {
  const mq = window.matchMedia("(prefers-color-scheme: dark)")

  const isDarkData
    = localStorage.dark == null ? mq.matches : JSON.parse(localStorage.dark)
  isDark.value = isDarkData
  if (company.value) {
    onShowAgreementDialog()
    getCateringOrdersData()
  }
})

function onChange(drawer) {
  drawerStore.setDrawer(drawer)
}

// @ts-expect-error todo
const version = __APP_VERSION__
</script>

<template>
  <v-navigation-drawer
    :value="visible"
    class="d-print-none"
    app
    width="245px"
    @input="onChange"
  >
    <v-list
      shaped
      dense
    >
      <v-list-item>
        <v-row justify="center">
          <h3 :dark="isDark">
            {{ $t("App.title1") }}
          </h3>
        </v-row>
      </v-list-item>

      <v-list-item
        v-if="company"
        color="secondary"
        to="/reportBugOrSuggestion"
      >
        <v-badge
          v-if="isReportBugOrSuggestion"
          color="red"
          :content="isReportBugOrSuggestion"
        >
          <v-icon>
            {{ mdiBell }}
          </v-icon>
        </v-badge>

        <v-icon v-else>
          {{ mdiBell }}
        </v-icon>

        <span class="pl-4 text-subtitle-2">
          {{ $t("Navigation.reportBugOrSuggestion") }}
        </span>
      </v-list-item>

      <v-list-item
        v-if="company"
        color="secondary"
        to="/catering-orders"
      >
        <v-badge
          v-if="isCateringOrderToDo"
          color="red"
          :content="isCateringOrderToDo"
        >
          <v-icon>
            {{ mdiCartVariant }}
          </v-icon>
        </v-badge>

        <v-icon v-else>
          {{ mdiCartVariant }}
        </v-icon>

        <span class="pl-4 text-subtitle-2">
          {{ $t("Navigation.cateringOrders") }}
        </span>
      </v-list-item>

      <v-list-item-group>
        <TheMenuItem
          v-for="(item, index) in menuItems"
          :key="index"
          :item="item"
        />
      </v-list-item-group>
    </v-list>

    <v-spacer />

    <template #append>
      <v-divider />

      <!--
        <v-list-item
        color="secondary"
        dense
        to="/instructions"
        target="_blank"
        >
        <v-icon>
        {{ mdiInformationOutline }}
        </v-icon>

        <span class="pl-4 text-subtitle-2">
        {{ $t("instructions") }}
        </span>
        </v-list-item>

        <v-divider />
      -->

      <v-list-item
        color="secondary"
        dense
        @click.stop="toggleTheme2"
      >
        <v-icon>
          {{ isDark ? mdiWeatherSunny : mdiWeatherNight }}
        </v-icon>

        <span class="pl-4 text-subtitle-2">
          {{ $t("darkTheme") }}
        </span>
      </v-list-item>

      <v-divider />

      <v-list-item
        color="secondary"
        dense
        to="/sign-out"
      >
        <v-icon>{{ mdiLogout }}</v-icon>

        <span class="pl-4 text-subtitle-2">
          {{ $t("Navigation.sign-out") }}
        </span>
      </v-list-item>

      <v-divider />

      <v-list-item
        dense
        class="text-subtitle-2"
      >
        {{ version }}
      </v-list-item>
    </template>

    <v-dialog
      v-model="showAgreementDialog"
      scrollable
      max-width="600px"
      @click.stop="close"
      @keydown.esc="close"
      @click:outside="close"
    >
      <TheDialogInfo
        :loading="false"
        :title="$t('CompanyView.agreementInfo').toString()"
        :on-close="close"
      >
        <span>
          {{ agreementText }}
        </span>
      </TheDialogInfo>
    </v-dialog>
  </v-navigation-drawer>
</template>

<style scoped>
.logo-radius {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-left: 10px;
}
</style>
