export class NotificationInfoModel {
  lastNotificationMessage: string | null
  lastNotificationTime: string | null
  lastNotificationType: string | null
  notificationCount: number | null

  constructor(data) {
    this.notificationCount
      = data != null
        ? Number(data.notificationCount || 0)
        : 0
    this.lastNotificationTime
      = data != null
        ? data.lastNotificationTime || null
        : null
    this.lastNotificationType
      = data != null
        ? data.lastNotificationType || null
        : null
    this.lastNotificationMessage
      = data != null
        ? data.lastNotificationMessage || null
        : null
  }

  toMap() {
    return {
      "lastNotificationMessage": this.lastNotificationMessage,
      "lastNotificationTime": this.lastNotificationTime,
      "lastNotificationType": this.lastNotificationType,
      "notificationCount": this.notificationCount,
    }
  }
}
