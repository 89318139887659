export const isPending = ({ status }) => status === "pending"

export const isApproved = ({ status }) => status === "approved"

export const isApprovedOrPartialRefund = ({ status }) => status === "approved" || status === "partialRefund"

export const sumFunc = (price1, price2) => Number(price1) + Number(price2)

export function getTotal(product) {
  const { count, price } = product
  return product?.loyaltyProgramCount
    ? (count - product?.loyaltyProgramCount) * price
    : count * price
}

export const getTotalSimple = ({ count, price }) => count * price

export function getCutleryTotal(order) {
  return order.cutleryCount
    ? order.cutleryCount * order.cutleryPrice
    : 0
}

export function getPaperBagTotal(order) {
  return order.paperBagCount
    ? order.paperBagCount * order.paperBagPrice
    : 0
}

export function getOrdersTotal(orders) {
  return orders
    .map(getTotal)
    .reduce(
      sumFunc,
      0,
    )
}

export function getOrdersTotalSubsidy(orders, subsidyPrice = 0) {
  const result = orders
    .map(getTotal)
    .reduce(
      sumFunc,
      0,
    )
  const sum = subsidyPrice >= result
    ? 0
    : result - subsidyPrice

  return sum
}

export function getOrdersTotalWithoutLoyaltyProgramCount(orders) {
  return orders
    .map(getTotalSimple)
    .reduce(
      sumFunc,
      0,
    )
}

export const roundResult = (sum = 0) => Math.ceil(sum * 100) / 100

export function getTotalWithSalesPercentage(order, sum) {
  if (order.salesPercentage && order.salesPercentage !== 0)
    return Math.round(sum * (1 - order.salesPercentage) * 100) / 100

  return roundResult(sum)
}
