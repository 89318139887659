import { captureException } from "@sentry/browser"
import { getDocs, increment, onSnapshot, serverTimestamp, updateDoc } from "firebase/firestore"
import { getIdFromRef } from "../helpers/getIdFromRef"
import {
  getCutleryTotal,
  getOrdersTotal,
  getPaperBagTotal,
  getTotalWithSalesPercentage,
  isApproved,
} from "../helpers/orders"
import {
  getSingleOrdersStatistic,
  ordersDate,
  ordersFixedSales,
  ordersIsNewUser,
  ordersLoyaltyProgram,
  ordersOnlinePayment,
  ordersSalesPercentage,
  ordersStatistic,
  ordersSubsidy,
} from "../firestoreWrappers"
import { mapTimeFromFirebaseFormat, toDayjsDate } from "../helpers/time"
import { dataRange } from "../helpers/range"
import { errorDefault } from "../helpers/snackbar"

import { isManagerOrDeliverOrOwner } from "../helpers/roles"
import { mapDateToFirebaseFormat } from "../helpers/timeFirebase"
import store from "."
import OrderModel from "~/models/OrderModel"

const statusOrder = {
  "approved": "approved",
  "pending": "pending",
  "rejected": "rejected",
}

const FirebaseFunctionsNames = {
  "getMoneyForOrder": "/getMoneyForOrder",
  "orderStatusChanged": "/orderStatusChanged",
  "refundMoney": "/refundMoney",
}

function onErrorShowSnackbar(error) {
  store.dispatch(
    "snackbar/showSnackbar",
    errorDefault(error),
  )
}

function getCollection(collection) {
  return isManagerOrDeliverOrOwner(collection)
    ? `${collection}s`
    : ""
}

function removeUnusedKeysFromOrders(index, status) {
  return ({
    _loading,
    "index": orderIndex,
    ...item
  }) => (orderIndex === index
    ? {
        ...item,
        status,
      }
    : item)
}

// @ts-expect-error
const isProduction = import.meta.env.VITE_CI_COMMIT_REF_NAME === "master"
// @ts-expect-error
|| import.meta.env.VITE_CI_COMMIT_REF_NAME === "legacy"
// @ts-expect-error
|| import.meta.env.VITE_CI_COMMIT_REF_NAME === "fix-master"

const baseURLProduction = "https://europe-west1-good-dystrybucja.cloudfunctions.net"
const baseURLStaging = "https://europe-west1-good-dystrybucja.cloudfunctions.net"

const baseURL = isProduction
  ? baseURLProduction
  : baseURLStaging

const HEADERS = {
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
  "Access-Control-Allow-Methods": "POST",
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  "Accept": "application/json, text/plain, */*",
}

const removeEmpty = obj => Object.keys(obj).forEach(key => obj[key] == null && delete obj[key])

function sentNotificationOrderStatusChanged(orderStatus, userData, company, route, order, additionalData = {}) {
  const callData = {
    "deliverName": route.isSubDeliver
      ? `${route.subDeliverFirstName} ${route.subDeliverLastName}`
      : `${route.deliverFirstName} ${route.deliverLastName}`,
    orderStatus,
    "title": company.name,
    "userRef": getIdFromRef(order.user),
    ...additionalData,
  }

  removeEmpty(callData)

  fetch(
    baseURL + FirebaseFunctionsNames.orderStatusChanged,
    {
      "method": "POST",
      "headers": {
        ...HEADERS,
        "collection": getCollection(userData.role),
        "uid": userData.reference.id,
      },
      "body": JSON.stringify(callData),
    },
  ).catch(onErrorShowSnackbar)
}

function sentNotificationGetMoneyForOrder(userData, company, order, price, suppliers, onFinally = () => {}) {
  const supplier = suppliers.filter(({ "reference": { id } }) => id === order.supplier.id)

  const getSubMerchantId = companyData => (companyData?.subMerchantIds && companyData?.subMerchantIds[supplier?.place?.city]
    ? companyData?.subMerchantIds[supplier?.place?.city]
    : companyData?.subMerchantId)

  const approvedOrders = order.orders.filter(isApproved)

  const orderTotal = getOrdersTotal(approvedOrders)
  const paperBag = getPaperBagTotal(order)
  const cutleryTotal = getCutleryTotal(order)

  const total = getTotalWithSalesPercentage(
    order,
    orderTotal + paperBag + cutleryTotal - price,
  )

  const callData = {
    "amount": total,
    "authorizationId": order.paymentId?.authorizationId,
    "description": `Zamówienie Nr ${order.orderNumber}`,
    "orderRef": order.reference.id,
    "saleId": order.paymentId.saleId,
    "subMerchantFee": company?.subMerchantFee,
    "subMerchantId": getSubMerchantId(company),
    "userRef": getIdFromRef(order.user),
  }

  removeEmpty(callData)

  fetch(
    baseURL + FirebaseFunctionsNames.getMoneyForOrder,
    {
      "method": "POST",
      "headers": {
        ...HEADERS,
        "collection": getCollection(userData.role),
        "uid": userData.reference.id,
      },
      "body": JSON.stringify(callData),
    },
  )
    .catch(onErrorShowSnackbar)
    .finally(onFinally)
}

function sentNotificationRefundMoney(userData, company, order, price, suppliers, isCurrentDate, onFinally = () => {}) {
  const supplier = suppliers.find(({ "reference": { id } }) => id === order.supplier.id)
  const getSubMerchant = companyData => (companyData?.subMerchantIds && companyData?.subMerchantIds[supplier?.place?.city]
    ? companyData?.subMerchantIds[supplier?.place?.city]
    : companyData?.subMerchantId)

  const approvedOrders = order.orders.filter(isApproved)
  const roundResult = (sum = 0) => Math.round(sum * 100) / 100

  const getTotalWithSalesPercentageNew = (orderTmp, sum) => {
    if (orderTmp.salesPercentage && orderTmp.salesPercentage !== 0)
      return Math.round(sum * (1 - orderTmp.salesPercentage) * 100) / 100
    else if (orderTmp.fixedSalesAmount && orderTmp.fixedSalesAmount !== 0)
      return Math.round(sum - orderTmp.fixedSalesAmount)

    return roundResult(sum)
  }

  const orderTotal = getOrdersTotal(approvedOrders)
  const paperBag = getPaperBagTotal(approvedOrders)
  const cutleryTotal = getCutleryTotal(order)

  const total = getTotalWithSalesPercentageNew(
    order,
    orderTotal + paperBag + cutleryTotal - price,
  )

  const callData = {
    "amount": total,
    "description": `Zamówienie Nr ${order.orderNumber}`,
    "orderRef": order.reference.id,
    "saleId": order.paymentId.saleId,
    "subMerchantId": getSubMerchant(company),
    "userRef": getIdFromRef(order.user),
  }

  removeEmpty(callData)

  fetch(
    baseURL + FirebaseFunctionsNames.refundMoney,
    {
      "method": "POST",
      "headers": {
        ...HEADERS,
        "collection": getCollection(userData.role),
        "uid": userData.reference.id,
      },
      "body": JSON.stringify(callData),
    },
  )
    .catch(onErrorShowSnackbar)
    .then(async () => {
      if (!isCurrentDate) {
        try {
          let newDate
          const orderDate = toDayjsDate(order.date)
          const tomorrowOrderTime = toDayjsDate(company.tomorrowOrderTime)
          if (
            orderDate.hour() > tomorrowOrderTime.hour()
            || (orderDate.hour() === tomorrowOrderTime.hour() && orderDate.minute() > tomorrowOrderTime.minute())
          ) {
            const days = [
              "sunday",
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
            ]

            newDate = toDayjsDate(order.date).hour(24)
              .minute(0)
              .second(0)
              .millisecond(0)

            while (!company.workingDays.includes(days[newDate.day() % 7])) {
              newDate = newDate.add(
                1,
                "day",
              )
            }
          }
          else {
            newDate = toDayjsDate(order.date).hour(0)
              .minute(0)
              .second(0)
              .millisecond(0)
          }

          const response = await getDocs(getSingleOrdersStatistic(
            company.reference,
            supplier.place.city,
            mapDateToFirebaseFormat(newDate.toDate()),
          ))

          if (!response.empty) {
            if (order.paymentStatus === "partialRefund") {
              const data = {
                "onlineTransactionReports": {
                  ...response.docs[0].data().onlineTransactionReports,
                },
              }
              data.onlineTransactionReports.partialRefundTransactions -= total
              data.onlineTransactionReports.partialRefundTransactionsCount -= 1
              data.onlineTransactionReports.refundTransactions += total
              data.onlineTransactionReports.refundTransactionsCount += 1
              await updateDoc(
                response.docs[0].ref,
                data,
              )
            }
            else {
              const data = {
                "onlineTransactionReports": {
                  ...response.docs[0].data().onlineTransactionReports,
                },
              }
              data.onlineTransactionReports.successTransactions -= total
              data.onlineTransactionReports.successTransactionsCount -= 1
              data.onlineTransactionReports.refundTransactions += total
              data.onlineTransactionReports.refundTransactionsCount += 1

              await updateDoc(
                response.docs[0].ref,
                data,
              )
            }
          }
        }
        catch (error) {
          captureException(error)
        }
      }
    })
    .finally(onFinally)
}

function onFinallySharedLoading() {
  store.dispatch(
    "shared/setLoading",
    false,
  )
}

function updateProduct(index, key, value) {
  return (product) => {
    if (product.index === index)
      product[key] = value

    return product
  }
}

function initialState() {
  return {
    "orders": null,
    "ordersSubsidy": null,
    "ordersSubsidyLoading": null,
    "ordersSubsidyError": null,
    "statisticOrders": null,
    "unsubscribe": null,

    "ordersOnlinePeyment": null,
    "ordersOnlinePeymentLoading": null,
    "ordersOnlinePeymentError": null,

    "ordersSalesPercentage": null,
    "ordersSalesPercentageLoading": null,
    "ordersSalesPercentageError": null,

    "ordersFixedSales": null,
    "ordersFixedSalesLoading": null,
    "ordersFixedSalesError": null,

    "ordersLoyaltyProgram": null,
    "ordersLoyaltyProgramLoading": null,
    "ordersLoyaltyProgramError": null,

    "ordersIsNewUser": null,
    "ordersIsNewUserLoading": null,
    "ordersIsNewUserError": null,
  }
}

export const PAYMENT_METHODS_SUBSIDY = [
  "subsidy",
  "subsidyGooglePay",
  "subsidyBlik",
  "subsidyCardOnline",
  "subsidyCash",
  "subsidyCardOnDelivery",
  "subsidyBankTransfers",
]

export function mapOrders(data) {
  return new OrderModel(
    data.data(),
    data.ref,
  )
}

export const Order = {
  "paymentStatus": {
    "success": "success",
    "waiting": "waiting",
    "failed": "failed",
    "refund": "refund",
    "partialRefund": "partialRefund",
  },
  "completementStatus": {
    "waitingFirmAccept": "waitingFirmAccept",
    "waitingCustomerAccept": "waitingCustomerAccept",
    "completed": "completed",
    "cancelled": "cancelled",
    "rejected": "rejected",
    "failed": "failed",
  },
  "orderStatus": {
    "waitingPackaging": "waitingPackaging",
    "packaged": "packaged",
    "delivered": "delivered",
  },
  "paymentMethod": {
    "cardOnline": "cardOnline",
    "cardOnDelivery": "cardOnDelivery",
    "cash": "cash",
    "blik": "blik",
    "bankTransfers": "bankTransfers",
  },
}

export default {
  "namespaced": true,
  "state": initialState(),
  "actions": {
    resetState({
      commit,
      "rootState": {
        "orders": { unsubscribe },
      },
    }) {
      if (unsubscribe)
        unsubscribe()

      commit("RESET_STATE")
    },
    getOrders(
      {
        commit,
        "rootState": {
          app,
          "orders": { "orders": ordersList, unsubscribe },
        },
      },
      { startDateFirebase, endDateFirebase, route, dateChanged = false },
    ) {
      const company = app?.userData?.company
      if (!company)
        return

      const routeId = getIdFromRef(route.reference)
      if (ordersList === null || !ordersList[routeId] || dateChanged) {
        if (unsubscribe) {
          unsubscribe()
          commit(
            "SAVE_UNSUBSCRIBE",
            null,
          )
        }

        const onErrorOnSnapshot = (error) => {
          if (error.code !== "permission-denied") {
            store.dispatch(
              "shared/error",
              error,
            )
            store.dispatch(
              "snackbar/showSnackbar",
              errorDefault(error),
            )
          }
        }

        const onSuccess = ({ docs }) => {
          commit(
            "SET_ORDERS",
            {
              ...ordersList,
              [routeId]: docs.map(mapOrders),
            },
          )
          onFinallySharedLoading()
        }

        store.dispatch("shared/init")
        const unsubscribeNew = onSnapshot(
          ordersDate(
            company,
            startDateFirebase,
            endDateFirebase,
            route.reference,
          ),
          onSuccess,
          onErrorOnSnapshot,
        )

        commit(
          "SAVE_UNSUBSCRIBE",
          unsubscribeNew,
        )
      }
      else {
        commit(
          "SET_ORDERS",
          {
            ...ordersList,
          },
        )
      }
    },
    getOrdersSubsidy(
      {
        commit,
        "rootState": {
          "app": {
            "userData": { company },
          },
        },
      },
      { city, date, startDate, endDate, suppliers },
    ) {
      store.dispatch("shared/init")
      commit("GET_ORDERS_SUBSIDY_REQUEST")

      const onError = (error) => {
        console.error(error)
        commit(
          "GET_ORDERS_SUBSIDY_ERROR",
          error,
        )
        store.dispatch(
          "shared/error",
          error,
        )
        store.dispatch(
          "snackbar/showSnackbar",
          errorDefault(error),
        )
      }

      const onSuccess = (data) => {
        const result = data.map(({ docs }) => docs).flat()
        commit(
          "GET_ORDERS_SUBSIDY_SUCCESS",
          {
            city,
            date,
            "orders": result.map(mapOrders),
          },
        )
        onFinallySharedLoading()
      }

      const suppliersPromises = suppliers.map(supplier => getDocs(ordersSubsidy(
        company,
        startDate,
        endDate,
        supplier.reference,
      )))
      Promise.all(suppliersPromises).then(onSuccess)
        .catch(onError)
    },
    getOrdersOnlinePayment(
      {
        commit,
        "rootState": {
          "app": {
            "userData": { company },
          },
        },
      },
      { city, date, startDate, endDate },
    ) {
      store.dispatch("shared/init")

      commit("GET_ORDERS_ONLINE_PEYMENT_REQUEST")

      const onError = (error) => {
        console.error(error)
        commit(
          "GET_ORDERS_ONLINE_PEYMENT_ERROR",
          error,
        )
        store.dispatch(
          "shared/error",
          error,
        )
        store.dispatch(
          "snackbar/showSnackbar",
          errorDefault(error),
        )
      }

      const onSuccess = ({ docs }) => {
        commit(
          "GET_ORDERS_ONLINE_PEYMENT_SUCCESS",
          {
            city,
            date,
            "orders": docs.map(mapOrders),
          },
        )
        onFinallySharedLoading()
      }

      getDocs(ordersOnlinePayment(
        company,
        startDate,
        endDate,
      )).then(onSuccess)
        .catch(onError)
    },

    getOrdersFixedSales(
      {
        commit,
        "rootState": {
          "app": {
            "userData": { company },
          },
        },
      },
      { city, date, startDate, endDate },
    ) {
      store.dispatch("shared/init")

      commit("GET_ORDERS_SALES_FIXED_REQUEST")

      const onError = (error) => {
        console.error(error)
        store.dispatch(
          "shared/error",
          error,
        )
        store.dispatch(
          "snackbar/showSnackbar",
          errorDefault(error),
        )
      }

      const onSuccess = (requestData) => {
        commit(
          "GET_ORDERS_SALES_FIXED_SUCCESS",
          {
            city,
            date,
            "orders": requestData.docs.map(mapOrders),
          },
        )
        onFinallySharedLoading()
      }

      const promiseOrdersFixedSales = getDocs(ordersFixedSales(
        company,
        startDate,
        endDate,
      ))

      promiseOrdersFixedSales.then(onSuccess).catch(onError)
    },
    getOrdersSalesPercentage(
      {
        commit,
        "rootState": {
          "app": {
            "userData": { company },
          },
        },
      },
      { city, date, startDate, endDate },
    ) {
      store.dispatch("shared/init")

      commit("GET_ORDERS_SALES_PERCENTAGE_REQUEST")

      const onError = (error) => {
        console.error(error)
        commit(
          "GET_ORDERS_SALES_PERCENTAGE_ERRROR",
          error,
        )
        store.dispatch(
          "shared/error",
          error,
        )
        store.dispatch(
          "snackbar/showSnackbar",
          errorDefault(error),
        )
      }

      const onSuccess = (requestData) => {
        commit(
          "GET_ORDERS_SALES_PERCENTAGE_SUCCESS",
          {
            city,
            date,
            "orders": requestData
              .map(item => item.docs)
              .flat()
              .map(mapOrders),
          },
        )
        onFinallySharedLoading()
      }

      const promiseordersSalesPercentage = dataRange.map(item => getDocs(ordersSalesPercentage(
        company,
        startDate,
        endDate,
        item,
      )))

      Promise.all(promiseordersSalesPercentage).then(onSuccess)
        .catch(onError)
    },

    getOrdersLoyaltyProgram(
      {
        commit,
        "rootState": {
          "app": {
            "userData": { company },
          },
        },
      },
      { city, date, startDate, endDate },
    ) {
      store.dispatch("shared/init")

      commit("GET_ORDERS_LOYALTY_PROGRAM_REQUEST")

      const onError = (error) => {
        console.error(error)
        commit(
          "GET_ORDERS_LOYALTY_PROGRAM_ERRROR",
          error,
        )
        store.dispatch(
          "shared/error",
          error,
        )
        store.dispatch(
          "snackbar/showSnackbar",
          errorDefault(error),
        )
      }

      const onSuccess = ({ docs }) => {
        commit(
          "GET_ORDERS_LOYALTY_PROGRAM_SUCCESS",
          {
            city,
            date,
            "orders": docs.map(mapOrders),
          },
        )
        onFinallySharedLoading()
      }

      getDocs(ordersLoyaltyProgram(
        company,
        startDate,
        endDate,
      )).then(onSuccess)
        .catch(onError)
    },

    getOrdersIsNewUser(
      {
        commit,
        "rootState": {
          "app": {
            "userData": { company },
          },
        },
      },
      { city, date, startDate, endDate },
    ) {
      store.dispatch("shared/init")

      commit("GET_ORDERS_IS_NEW_USER_REQUEST")

      const onError = (error) => {
        console.error(error)
        commit(
          "GET_ORDERS_IS_NEW_USER_ERRROR",
          error,
        )
        store.dispatch(
          "shared/error",
          error,
        )
        store.dispatch(
          "snackbar/showSnackbar",
          errorDefault(error),
        )
      }

      const onSuccess = ({ docs }) => {
        commit(
          "GET_ORDERS_IS_NEW_USER_SUCCESS",
          {
            city,
            date,
            "orders": docs.map(mapOrders),
          },
        )
        onFinallySharedLoading()
      }

      getDocs(ordersIsNewUser(
        company,
        startDate,
        endDate,
      )).then(onSuccess)
        .catch(onError)
    },

    async changeRouteOrdersCount(
      {
        "rootState": {
          "app": { userData },
        },
      },
      { route, ordersCount },
    ) {
      await updateDoc(
        route.reference,
        {
          "ordersCount": Number(ordersCount),
          "lastUpdateByUser": userData.reference,
          "lastUpdateTime": serverTimestamp(),
        },
      )
    },

    async changeRoutePendingOrdersCount(
      {
        "rootState": {
          "app": { userData },
        },
      },
      { route, pendingOrders },
    ) {
      await updateDoc(
        route.reference,
        {
          "pendingOrders": Number(pendingOrders),
          "lastUpdateByUser": userData.reference,
          "lastUpdateTime": serverTimestamp(),
        },
      )
    },
    getStatisticOrders(
      {
        commit,
        "rootState": {
          "app": {
            "userData": { company },
          },
        },
      },
      { startDateFirebase, endDateFirebase },
    ) {
      store.dispatch("shared/init")

      const onErrorOnSnapshot = (error) => {
        if (error.code !== "permission-denied") {
          store.dispatch(
            "shared/error",
            error,
          )
          store.dispatch(
            "snackbar/showSnackbar",
            errorDefault(error),
          )
        }
      }

      const onSuccess = ({ docs }) => {
        commit(
          "SET_STATISTIC_ORDERS",
          docs.map(mapOrders),
        )
        onFinallySharedLoading()
      }

      getDocs(ordersStatistic(
        company,
        startDateFirebase,
        endDateFirebase,
      )).then(onSuccess)
        .catch(onErrorOnSnapshot)
    },
    approveProducts(
      {
        commit,
        "rootState": {
          "app": { userData },
        },
      },
      { order, index },
    ) {
      commit(
        "SET_LOADING_ORDER",
        {
          order,
          index,
          "loading": true,
        },
      )

      const onFinally = () => {
        commit(
          "SET_LOADING_ORDER",
          {
            order,
            index,
            "loading": false,
          },
        )
      }

      updateDoc(
        order.reference,
        {
          "orders": order.orders.map(removeUnusedKeysFromOrders(
            index,
            statusOrder.approved,
          )),
          "lastUpdateByUser": userData.reference,
          "lastUpdateTime": serverTimestamp(),
        },
      )
        .catch(onErrorShowSnackbar)
        .finally(onFinally)
    },
    rejectProducts(
      {
        commit,
        "rootState": {
          "app": { userData },
        },
      },
      { order, index },
    ) {
      commit(
        "SET_LOADING_ORDER",
        {
          order,
          index,
          "loading": true,
        },
      )

      const onFinally = () => {
        commit(
          "SET_LOADING_ORDER",
          {
            order,
            index,
            "loading": false,
          },
        )
      }

      updateDoc(
        order.reference,
        {
          "orders": order.orders.map(removeUnusedKeysFromOrders(
            index,
            statusOrder.rejected,
          )),
          "lastUpdateByUser": userData.reference,
          "lastUpdateTime": serverTimestamp(),
        },
      )
        .catch(onErrorShowSnackbar)
        .finally(onFinally)
    },
    approveWholeOrder(
      {
        commit,
        "rootState": {
          "app": { userData },
        },
      },
      { order, route, company },
    ) {
      commit(
        "SET_LOADING_WHOLE_ORDER",
        {
          order,
          "loading": true,
        },
      )

      const onSuccessCompleted = () => {
        store.dispatch(
          "routes/decreasePendingOrders",
          route,
        )
        store.dispatch(
          "suppliers/updateOrder",
          {
            "supplier": order.supplier,
            "pending": -1,
            "approved": 1,
          },
        )

        sentNotificationOrderStatusChanged(
          "accepted",
          userData,
          company,
          route,
          order,
        )
      }

      const onFinally = () => {
        commit(
          "SET_LOADING_WHOLE_ORDER",
          {
            order,
            "loading": false,
          },
        )
      }

      updateDoc(
        order.reference,
        {
          "completementStatus": Order.completementStatus.completed,
          "lastUpdateByUser": userData.reference,
          "lastUpdateTime": serverTimestamp(),
        },
      )
        .then(onSuccessCompleted)
        .catch(onErrorShowSnackbar)
        .finally(onFinally)
    },
    approveWholeOrderAndGetPayment(
      {
        commit,
        "rootState": {
          "app": { userData },
          "suppliers": { suppliers },
        },
      },
      { order, route, company, price },
    ) {
      commit(
        "SET_LOADING_WHOLE_ORDER",
        {
          order,
          "loading": true,
        },
      )

      const onSuccess = () => {
        store.dispatch(
          "routes/decreasePendingOrders",
          route,
        )
        store.dispatch(
          "suppliers/updateOrder",
          {
            "supplier": order.supplier,
            "pending": -1,
            "approved": 1,
          },
        )

        sentNotificationOrderStatusChanged(
          "accepted",
          userData,
          company,
          route,
          order,
        )

        sentNotificationGetMoneyForOrder(
          userData,
          company,
          order,
          price,
          suppliers,
        )
      }

      const onFinally = () => {
        commit(
          "SET_LOADING_WHOLE_ORDER",
          {
            order,
            "loading": false,
          },
        )
      }

      updateDoc(
        order.reference,
        {
          "completementStatus": Order.completementStatus.completed,
          "lastUpdateByUser": userData.reference,
          "lastUpdateTime": serverTimestamp(),
        },
      )
        .then(onSuccess)
        .catch(onErrorShowSnackbar)
        .finally(onFinally)
    },
    approveWholeOrderAndDeliveryTime(
      {
        "rootState": {
          "app": { userData },
        },
      },
      { order, route, company, deliveryTime, time },
    ) {
      store.dispatch("shared/init")

      const onSuccessCompleted = () => {
        store.dispatch(
          "routes/decreasePendingOrders",
          route,
        )
        store.dispatch(
          "suppliers/updateOrder",
          {
            "supplier": order.supplier,
            "pending": -1,
            "approved": 1,
          },
        )

        sentNotificationOrderStatusChanged(
          "accepted",
          userData,
          company,
          route,
          order,
          { time },
        )
      }

      const onError = (error) => {
        console.error(error)
        store.dispatch(
          "shared/setError",
          error,
        )
        store.dispatch(
          "snackbar/showSnackbar",
          errorDefault(error),
        )
      }

      updateDoc(
        order.reference,
        {
          "completementStatus": Order.completementStatus.completed,
          deliveryTime,
          "lastUpdateByUser": userData.reference,
          "lastUpdateTime": serverTimestamp(),
        },
      )
        .then(onSuccessCompleted)
        .catch(onError)
        .finally(onFinallySharedLoading)
    },
    changeTime(
      {
        "rootState": {
          "app": { userData },
        },
      },
      { order, route, company, deliveryTime },
    ) {
      store.dispatch("shared/init")

      const onSuccessCompleted = () => {
        sentNotificationOrderStatusChanged(
          "deliveryTimeChanged",
          userData,
          company,
          route,
          order,
          {
            "orderStatus": order.deliveryTime
              ? "deliveryTimeChanged"
              : "deliveryTimeSet",
            "deliveryTime": mapTimeFromFirebaseFormat(deliveryTime),
          },
        )
      }

      const onError = (error) => {
        console.error(error)
        store.dispatch(
          "shared/setError",
          error,
        )
        store.dispatch(
          "snackbar/showSnackbar",
          errorDefault(error),
        )
      }

      updateDoc(
        order.reference,
        {
          deliveryTime,
          "lastUpdateByUser": userData.reference,
          "lastUpdateTime": serverTimestamp(),
        },
      )
        .then(onSuccessCompleted)
        .catch(onError)
        .finally(onFinallySharedLoading)
    },
    approveWholeOrderAndDeliveryTimeAndGetPayment(
      {
        "rootState": {
          "app": { userData },
          "suppliers": { suppliers },
        },
      },
      { order, route, company, deliveryTime, time, price },
    ) {
      store.dispatch("shared/init")

      const onSuccess = () => {
        store.dispatch(
          "routes/decreasePendingOrders",
          route,
        )
        store.dispatch(
          "suppliers/updateOrder",
          {
            "supplier": order.supplier,
            "pending": -1,
            "approved": 1,
          },
        )

        sentNotificationOrderStatusChanged(
          "accepted",
          userData,
          company,
          route,
          order,
          { time },
        )

        sentNotificationGetMoneyForOrder(
          userData,
          company,
          order,
          price,
          suppliers,
        )
      }

      const onError = (error) => {
        console.error(error)
        store.dispatch(
          "shared/setError",
          error,
        )
        store.dispatch(
          "snackbar/showSnackbar",
          errorDefault(error),
        )
      }

      updateDoc(
        order.reference,
        {
          "completementStatus": Order.completementStatus.completed,
          deliveryTime,
          "lastUpdateByUser": userData.reference,
          "lastUpdateTime": serverTimestamp(),
        },
      )
        .then(onSuccess)
        .catch(onError)
        .finally(onFinallySharedLoading)
    },
    approveWholeOrderWithMessage(
      {
        commit,
        "rootState": {
          "app": { userData },
        },
      },
      { order, route, company, messages },
    ) {
      commit(
        "SET_LOADING_WHOLE_ORDER",
        {
          order,
          "loading": true,
        },
      )

      const onSuccess = () => {
        store.dispatch(
          "routes/decreasePendingOrders",
          route,
        )
        store.dispatch(
          "suppliers/updateOrder",
          {
            "supplier": order.supplier,
            "pending": 0,
            "approved": 1,
          },
        )

        sentNotificationOrderStatusChanged(
          "rejected",
          userData,
          company,
          route,
          order,
          {
            "message": messages.message,
            "messageEN": messages.messageEN,
          },
        )
      }

      const onFinally = () => {
        commit(
          "SET_LOADING_WHOLE_ORDER",
          {
            order,
            "loading": false,
          },
        )
      }

      updateDoc(
        order.reference,
        {
          "completementStatus": Order.completementStatus.waitingCustomerAccept,
          "lastUpdateByUser": userData.reference,
          "lastUpdateTime": serverTimestamp(),
        },
      )
        .then(onSuccess)
        .catch(onErrorShowSnackbar)
        .finally(onFinally)
    },

    getPayment(
      {
        commit,
        "rootState": {
          "app": { userData },
          "suppliers": { suppliers },
        },
      },
      { order, company, price },
    ) {
      commit(
        "SET_LOADING_WHOLE_ORDER",
        {
          order,
          "loading": true,
        },
      )

      const onFinally = () => {
        commit(
          "SET_LOADING_WHOLE_ORDER",
          {
            order,
            "loading": false,
          },
        )
      }

      sentNotificationGetMoneyForOrder(
        userData,
        company,
        order,
        price,
        suppliers,
        onFinally,
      )
    },
    refundMoney(
      {
        commit,
        "rootState": {
          "app": { userData },
          "suppliers": { suppliers },
        },
      },
      { order, company, price, isCurrentDate },
    ) {
      commit(
        "SET_LOADING_WHOLE_ORDER",
        {
          order,
          "loading": true,
        },
      )

      const onFinally = () => {
        commit(
          "SET_LOADING_WHOLE_ORDER",
          {
            order,
            "loading": false,
          },
        )
      }

      sentNotificationRefundMoney(
        userData,
        company,
        order,
        price,
        suppliers,
        isCurrentDate,
        onFinally,
      )
    },

    rejectWholeOrder(
      {
        commit,
        "rootState": {
          "app": { userData },
        },
      },
      { order, route, company },
    ) {
      commit(
        "SET_LOADING_WHOLE_ORDER",
        {
          order,
          "loading": true,
        },
      )

      const onSuccess = () => {
        store.dispatch(
          "routes/decreasePendingOrders",
          route,
        )
        store.dispatch(
          "suppliers/updateOrder",
          {
            "supplier": order.supplier,
            "pending": -1,
            "approved": 0,
          },
        )

        sentNotificationOrderStatusChanged(
          "discard",
          userData,
          company,
          route,
          order,
        )
      }

      const onFinally = () => {
        commit(
          "SET_LOADING_WHOLE_ORDER",
          {
            order,
            "loading": false,
          },
        )
      }

      updateDoc(
        order.reference,
        {
          "completementStatus": Order.completementStatus.rejected,
          "lastUpdateByUser": userData.reference,
          "lastUpdateTime": serverTimestamp(),
        },
      )
        .then(onSuccess)
        .catch(onErrorShowSnackbar)
        .finally(onFinally)
    },

    moveOrder(
      {
        commit,
        "rootState": {
          "orders": { orders },
          "app": { userData },
        },
      },
      { route, supplier, selectedOrder },
    ) {
      store.dispatch("shared/init")

      const oldRouteRef = selectedOrder.route

      const updateData = {
        "route": route.reference,
        supplier,
        "deliver": route.isSubDeliver
          ? route.subDeliver
          : route.deliver,
        "lastUpdateByUser": userData.reference,
        "lastUpdateTime": serverTimestamp(),
      }

      const onSuccess = () => {
        const routeId = route.reference.id
        const selectedOrderId = selectedOrder.reference.id
        const newOrder = {
          ...selectedOrder,
          ...updateData,
        }
        const mapOrdersFilter = order => (order.reference.id === selectedOrderId
          ? null
          : order)
        let newOrdersList = {
          ...orders,
        }
        if (orders[routeId]) {
          newOrdersList = {
            ...newOrdersList,
            [routeId]: [
              ...(orders[routeId] || []),
              newOrder,
            ],
          }
        }
        if (orders[oldRouteRef.id]) {
          newOrdersList = {
            ...newOrdersList,
            [oldRouteRef.id]: [...(orders[oldRouteRef.id] || []).filter(mapOrdersFilter)],
          }
        }
        commit(
          "SET_ORDERS",
          newOrdersList,
        )

        if (routeId !== oldRouteRef.id) {
          if (selectedOrder.completementStatus === Order.completementStatus.waitingFirmAccept) {
            updateDoc(
              route.reference,
              {
                "pendingOrders": increment(1),
                "lastUpdateByUser": userData.reference,
                "lastUpdateTime": serverTimestamp(),
              },
            )
          }
          updateDoc(
            route.reference,
            {
              "ordersCount": increment(1),
              "lastUpdateByUser": userData.reference,
              "lastUpdateTime": serverTimestamp(),
            },
          )

          if (selectedOrder.completementStatus === Order.completementStatus.waitingFirmAccept) {
            updateDoc(
              oldRouteRef,
              {
                "pendingOrders": increment(-1),
                "lastUpdateByUser": userData.reference,
                "lastUpdateTime": serverTimestamp(),
              },
            )
          }

          updateDoc(
            oldRouteRef,
            {
              "ordersCount": increment(-1),
              "lastUpdateByUser": userData.reference,
              "lastUpdateTime": serverTimestamp(),
            },
          )
        }
      }

      const onError = (error) => {
        console.error(error)
        store.dispatch(
          "shared/setError",
          error,
        )
        store.dispatch(
          "snackbar/showSnackbar",
          errorDefault(error),
        )
      }

      updateDoc(
        selectedOrder.reference,
        updateData,
      ).then(onSuccess)
        .catch(onError)
        .finally(onFinallySharedLoading)
    },
  },
  "mutations": {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    SET_ORDERS(state, orders) {
      state.orders = orders
    },

    SAVE_UNSUBSCRIBE(state, unsubscribe) {
      state.unsubscribe = unsubscribe
    },

    GET_ORDERS_SUBSIDY_REQUEST(state) {
      state.ordersSubsidyLoading = true
      state.ordersSubsidyError = null
    },

    GET_ORDERS_SUBSIDY_SUCCESS(state, { city, date, orders }) {
      const copy = { ...state.ordersSubsidy || {} }

      state.ordersSubsidy = {
        ...copy,
        [city]: {
          ...copy[city],
          [date]: orders,
        },
      }
      state.ordersSubsidyLoading = false
    },

    GET_ORDERS_SUBSIDY_ERROR(state, error) {
      state.ordersSubsidyLoading = false
      state.ordersSubsidyError = error
    },

    SET_STATISTIC_ORDERS(state, orders) {
      state.statisticOrders = orders
    },

    SET_LOADING_ORDER(state, { order, index, loading }) {
      const setLoading = (orderItem) => {
        if (orderItem.reference.id === order.reference.id) {
          return {
            ...orderItem,
            "orders": orderItem.orders.map(updateProduct(
              index,
              "loading",
              loading,
            )),
          }
        }
        return orderItem
      }

      state.orders = {
        ...state.orders,
        [getIdFromRef(order.route)]: state.orders[getIdFromRef(order.route)].map(setLoading),
      }
    },
    SET_LOADING_WHOLE_ORDER(state, { order, loading }) {
      const setLoading = (orderItem) => {
        if (orderItem.reference.id === order.reference.id)
          orderItem.loading = loading

        return orderItem
      }

      state.orders = {
        ...state.orders,
        [getIdFromRef(order.route)]: state.orders[getIdFromRef(order.route)].map(setLoading),
      }
    },
    SET_STATUS_WHOLE_ORDER(state, { order, completementStatus }) {
      const setLoading = (orderItem) => {
        if (orderItem.reference.id === order.reference.id)
          orderItem.completementStatus = completementStatus

        return orderItem
      }

      state.orders = {
        ...state.orders,
        [getIdFromRef(order.route)]: state.orders[getIdFromRef(order.route)].map(setLoading),
      }
    },

    GET_ORDERS_ONLINE_PEYMENT_REQUEST(state) {
      state.ordersOnlinePeymentLoading = true
      state.ordersOnlinePeymentError = null
    },

    GET_ORDERS_ONLINE_PEYMENT_SUCCESS(state, { city, date, orders }) {
      const copy = { ...state.ordersOnlinePeyment || {} }

      state.ordersOnlinePeyment = {
        ...copy,
        [city]: {
          ...copy[city],
          [date]: orders,
        },
      }
      state.ordersOnlinePeymentLoading = false
    },

    GET_ORDERS_ONLINE_PEYMENT_ERROR(state, error) {
      state.ordersOnlinePeymentLoading = false
      state.ordersOnlinePeymentError = error
    },

    GET_ORDERS_SALES_PERCENTAGE_REQUEST(state) {
      state.ordersSalesPercentageLoading = true
      state.ordersSalesPercentageError = null
    },
    GET_ORDERS_SALES_PERCENTAGE_SUCCESS(state, { city, date, orders }) {
      const copy = { ...state.ordersSalesPercentage || {} }

      state.ordersSalesPercentage = {
        ...copy,
        [city]: {
          ...copy[city],
          [date]: orders,
        },
      }
      state.ordersSalesPercentageLoading = false
    },
    GET_ORDERS_SALES_PERCENTAGE_ERRROR(state, error) {
      state.ordersSalesPercentageLoading = false
      state.ordersSalesPercentageError = error
    },

    GET_ORDERS_SALES_FIXED_REQUEST(state) {
      state.ordersFixedSalesLoading = true
      state.ordersFixedSalesError = null
    },
    GET_ORDERS_SALES_FIXED_SUCCESS(state, { city, date, orders }) {
      const copy = { ...state.ordersFixedSales || {} }

      state.ordersFixedSales = {
        ...copy,
        [city]: {
          ...copy[city],
          [date]: orders,
        },
      }
      state.ordersFixedSalesLoading = false
    },
    GET_ORDERS_SALES_FIXED_ERRROR(state, error) {
      state.ordersFixedSalesLoading = false
      state.ordersFixedSalesError = error
    },

    GET_ORDERS_LOYALTY_PROGRAM_REQUEST(state) {
      state.ordersLoyaltyProgramLoading = true
      state.ordersLoyaltyProgramError = null
    },
    GET_ORDERS_LOYALTY_PROGRAM_SUCCESS(state, { city, date, orders }) {
      const copy = { ...state.ordersLoyaltyProgram || {} }

      state.ordersLoyaltyProgram = {
        ...copy,
        [city]: {
          ...copy[city],
          [date]: orders,
        },
      }
      state.ordersLoyaltyProgramLoading = false
    },
    GET_ORDERS_LOYALTY_PROGRAM_ERRROR(state, error) {
      state.ordersLoyaltyProgramLoading = false
      state.ordersLoyaltyProgramError = error
    },

    GET_ORDERS_IS_NEW_USER_REQUEST(state) {
      state.ordersIsNewUserLoading = true
      state.ordersIsNewUserError = null
    },
    GET_ORDERS_IS_NEW_USER_SUCCESS(state, { city, date, orders }) {
      const copy = { ...state.ordersIsNewUser || {} }

      state.ordersIsNewUser = {
        ...copy,
        [city]: {
          ...copy[city],
          [date]: orders,
        },
      }
      state.ordersIsNewUserLoading = false
    },
    GET_ORDERS_IS_NEW_USER_ERRROR(state, error) {
      state.ordersIsNewUserLoading = false
      state.ordersIsNewUserError = error
    },

  },
}
