import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import Vue from "vue"
import { onAuthStateChanged } from "firebase/auth"
import { PiniaVuePlugin, createPinia } from "pinia"
import { createHead } from "@unhead/vue"
import { UnheadPlugin } from "@unhead/vue/vue2"
import { auth } from "./firebaseCore"
import i18n from "./i18n"
import App from "./App.vue"
import router from "./router/index"
import store from "./store/index"
import vuetify from "./vuetify"
import "uno.css"

const unhead = createHead()
Vue.use(UnheadPlugin)

Vue.config.productionTip = false

// @ts-expect-error
if (import.meta.env.VITE_CI_COMMIT_REF_NAME === "master") {
  Sentry.init({
    Vue,
    "dsn": "https://050ee9dd3762beabdb9c9b4e2d36af72@o84629.ingest.sentry.io/4506415543615488",
    "environment": "production",
    "integrations": [new Integrations.BrowserTracing()],
    "tracingOptions": {
      "trackComponents": true,
    },
    // @ts-expect-error
    "release": import.meta.env.VITE_VERSION || 0,
    "tracesSampleRate": 1.0,
  })
}

let app
const pinia = createPinia()
Vue.use(PiniaVuePlugin)

function onUserChange(user) {
  if (!app) {
    app = new Vue({
      "name": "VueApp",
      // @ts-expect-error
      unhead,
      vuetify,
      store,
      router,
      pinia,
      i18n,
      "render": func => func(App),
    }).$mount("#app")
  }
  if (user) {
    store.dispatch(
      "app/checkUserData",
      user,
    )
  }
}

onAuthStateChanged(
  auth,
  onUserChange,
)
