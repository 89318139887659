export class ProfileModel {
  email: string | null
  phone: string | null
  firstName: string | null
  lastName: string | null
  avatar: string | null
  cities: string | null

  constructor(data) {
    this.email = data.email || null
    this.phone = data.phone || null
    this.firstName = data.firstName || null
    this.lastName = data.lastName || null
    this.avatar = data.avatar || null
    this.cities = data.cities || null
  }

  toMap() {
    return {
      "phone": this.phone,
      "email": this.email?.trim() || null,
      "avatar": this.avatar,
      "firstName": this.firstName?.trim() || null,
      "lastName": this.lastName?.trim() || null,
      "cities": Array.isArray(this.cities)
        ? this.cities
        : [this.cities],
    }
  }
}
