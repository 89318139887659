import { EmployeeModel } from "./EmployeeModel"
import { RatingModel } from "./RatingModel"

export class DeliverModel extends EmployeeModel {
  road: string | null
  rating: RatingModel | null
  dailyHistoryRoad: string | null
  earnings: any
  visibilityAccess: any
  editDeliverSellsAccess: boolean
  editMenuReturns: boolean

  constructor(data, reference?) {
    super(
      data,
      reference,
    )
    this.road = data.road || []
    this.earnings = data.earnings || {}
    this.visibilityAccess = data.visibilityAccess || []
    this.rating = data?.rating
      ? new RatingModel(data.rating)
      : null
    this.dailyHistoryRoad = data.dailyHistoryRoad || []
    this.editDeliverSellsAccess = data.editDeliverSellsAccess || false
    this.editMenuReturns = data.editMenuReturns || false
  }

  toMap() {
    return {
      ...super.toMap(),
      "road": this.road,
      "earnings": this.earnings,
      "visibilityAccess": this.visibilityAccess,
      "rating": this.rating
        ? this.rating.toMap()
        : null,
      "dailyHistoryRoad": this.dailyHistoryRoad,
      "editDeliverSellsAccess": this.editDeliverSellsAccess,
      "editMenuReturns": this.editMenuReturns,
    }
  }
}

export function mapDelivers(deliver) {
  return new DeliverModel(
    deliver.data(),
    deliver.ref,
  )
}
