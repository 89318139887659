import { collection, getDocs, query, where } from "firebase/firestore"
import { firestore } from "../firebaseCore"

export const collectionOrders = collection(
  firestore,
  "orders",
)

export function statsFromDateRange(company, dateStart, dateEnd) {
  return query(
    collectionOrders,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "date",
      ">=",
      dateStart,
    ),
    where(
      "date",
      "<=",
      dateEnd,
    ),
  )
}

export function getStats(company,
  dateStart,
  dateEnd) {
  return getDocs(statsFromDateRange(
    company,
    dateStart,
    dateEnd,
  ))
}
