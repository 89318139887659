import type { DocumentReference, FieldValue } from "firebase/firestore"
import { AddressModel } from "./AddressModel"
import { AgreementModel } from "./AgreementModel"
import type { EarnsModel } from "./EarnsModel"

export class CompanyModel {
  nip: string | null
  address: AddressModel | null
  cities: string | null
  email: string | null
  isActive: boolean
  logoPath: string | null
  logo: string | null
  name: string | null
  phone: string | null
  useSms: boolean
  workingDays: string | null
  tomorrowOrderTime: string | null
  subMerchantId: string | null
  subMerchantIds: string | null
  subMerchantFee: string | null
  joiningTime: string | null
  acceptOrdersTime: { from: string; to: string } | null
  workingHours: { from: string; to: string } | null
  isProductCounter: string | null
  isUseBarCode: string | null
  isUseSubsidy: string | null
  canChangeBuyFunction: string | null
  isDetailSellReport: string | null
  createdByUser: DocumentReference | null
  lastUpdateByUser: DocumentReference | null
  lastUpdateTime: FieldValue | null
  isAllowFV: string | null
  agreement: AgreementModel | null
  ourEarns: EarnsModel | null
  reference: DocumentReference | null

  constructor(data, reference?) {
    this.nip = data.nip || null
    this.address = new AddressModel(data.address)
    this.cities = data.cities || null
    this.email = data.email || null
    this.isActive = data.isActive || false
    this.logoPath = data.logoPath || null
    this.logo = data.logo || null
    this.name = data.name || null
    this.phone = data.phone || null
    this.useSms = data.useSms || false
    this.workingDays = data.workingDays || null
    this.tomorrowOrderTime = data.tomorrowOrderTime || null
    this.subMerchantId = data.subMerchantId || null
    this.subMerchantIds = data.subMerchantIds || null
    this.subMerchantFee = data.subMerchantFee || null
    this.joiningTime = data.joiningTime || null
    this.acceptOrdersTime = data.acceptOrdersTime || null
    this.workingHours = data.workingHours || null
    this.isProductCounter = data.isProductCounter || false
    this.isUseBarCode = data.isUseBarCode || false
    this.isUseSubsidy = data.isUseSubsidy || false
    this.canChangeBuyFunction = data.canChangeBuyFunction || false
    this.isDetailSellReport = data.isDetailSellReport || false
    this.ourEarns = data.ourEarns || null

    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null
    this.isAllowFV = data.isAllowFV || false

    this.agreement = data.agreement
      ? new AgreementModel(data.agreement)
      : null

    this.reference = reference
  }

  toMap() {
    return {
      "nip": this.nip?.trim() || null,
      "address": this.address
        ? this.address.toMap()
        : null,
      "cities": this.cities,
      "email": this.email,
      "isActive": this.isActive,
      "logo": this.logo,
      "logoPath": this.logoPath,
      "name": this.name?.trim() || null,
      "phone": this.phone,
      "tomorrowOrderTime": this.tomorrowOrderTime,
      "subMerchantId": this.subMerchantId || null,
      "subMerchantIds": this.subMerchantIds || null,
      "subMerchantFee": this.subMerchantFee || null,
      "useSms": this.useSms,
      "workingDays": this.workingDays,
      "agreement": this.agreement
        ? this.agreement.toMap()
        : null,
      "joiningTime": this.joiningTime,
      "acceptOrdersTime": this.acceptOrdersTime || null,
      "workingHours": this.workingHours || null,
      "isProductCounter": this.isProductCounter || false,
      "isUseBarCode": this.isUseBarCode || false,
      "isUseSubsidy": this.isUseSubsidy || false,
      "canChangeBuyFunction": this.canChangeBuyFunction || false,
      "isDetailSellReport": this.isDetailSellReport || false,
      "ourEarns": this.ourEarns || null,

      "lastUpdateTime": this.lastUpdateTime || null,
      "lastUpdateByUser": this.lastUpdateByUser || false,
      "isAllowFV": this.isAllowFV || false,

    }
  }
}

export function mapCompany(company) {
  return new CompanyModel(
    company.data(),
    company.ref,
  )
}
