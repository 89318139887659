import { getDocs } from "firebase/firestore"
import {
  getSuppliersByDeliver,
  getUsersBySuppliers,
} from "../firestoreWrappers"
import { DeliverRankingModel } from "../models/DeliverRankingModel"

function initialState() {
  return {
    "deliversRanking": null,

    "fetchLoading": false,
    "fetchError": null,
  }
}

export default {
  "namespaced": true,
  "state": initialState(),
  "actions": {
    resetState({ commit }) {
      commit("RESET_STATE")
    },

    async getDeliversRanking({
      commit,
      "rootState": {
        "employees": { delivers, getdelivers },
      },
    }) {
      commit("GET_DELIVERS_RANKING_REQUEST")
      const deliverList = [
        ...delivers,
        ...getdelivers,
      ]
      const deliversRanking: Array<DeliverRankingModel> = []
      for (const deliver of deliverList) {
        let clientsCount = 0
        const suppliersList = await getDocs(getSuppliersByDeliver(deliver))
        for (const supplier of suppliersList.docs) {
          const userList = await getDocs(getUsersBySuppliers(supplier))
          clientsCount += userList.docs.length
        }
        deliversRanking.push(new DeliverRankingModel(
          deliver,
          clientsCount,
        ))
      }
      commit(
        "GET_DELIVERS_RANKING_SUCCESS",
        { deliversRanking },
      )
    },
  },
  "mutations": {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    GET_DELIVERS_RANKING_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    GET_DELIVERS_RANKING_SUCCESS(_state, { deliversRanking }) {
      _state.fetchLoading = false
      _state.fetchError = null
      _state.deliversRanking = deliversRanking
    },
    GET_DELIVERS_RANKING_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchError = error
    },
  },
}
