import { collection, getDocs, query, where } from "firebase/firestore"
import { firestore } from "../firebaseCore"

export const collectionPromoCodeStatistics = collection(
  firestore,
  "promoCodeStatistics",
)

export function getPromoCodeStatisticsCollection(company, startDate, endDate) {
  return getDocs(query(
    collectionPromoCodeStatistics,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "date",
      ">=",
      startDate,
    ),
    where(
      "date",
      "<",
      endDate,
    ),
  ))
}
