export class PlaceModel {
  address: string | null
  city: string | null
  floor: number | null
  name: string | null

  constructor(data) {
    this.address = data.address || null
    this.floor = data.floor
      ? Number(data.floor)
      : null
    this.name = data.name || null
    this.city = data.city || null
  }

  toMap() {
    return {
      "address": this.address?.trim() || null,
      "city": this.city,
      "floor": this.floor,
      "name": this.name?.trim() || null,
    }
  }
}
