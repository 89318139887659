import type { DocumentReference, FieldValue } from "firebase/firestore"
import { mapDateFromFirebaseFormat } from "../helpers/time"
import i18n from "~/i18n"

export class MessageModel {
  date: string | null
  name: string | null
  email: string | null
  phone: string | null
  category: string | null
  message: string | null
  status: string | null
  isVisible: boolean | null
  company: DocumentReference | null
  lastUpdateTime: FieldValue | null
  lastUpdateByUser: DocumentReference | null
  reference: DocumentReference | null

  constructor(data, reference?) {
    this.date = mapDateFromFirebaseFormat(data.date) || null
    this.name = data.name || null
    this.email = data.email || null
    this.phone = data.phone || null
    this.category = data.category || null
    this.message = data.message || null
    this.status = data.status || i18n.t("MessagesView.active")
    this.isVisible = data.isVisible || null
    this.company = data.company || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null
    this.reference = reference || null
  }

  toMap() {
    return {
      "date": this.date,
      "name": this.name?.trim() || null,
      "email": this.email,
      "phone": this.phone,
      "category": this.category,
      "message": this.message?.trim() || null,
      "status": this.status,
      "isVisible": this.isVisible || null,
      "company": this.company || null,
      "lastUpdateTime": this.lastUpdateTime || null,
      "lastUpdateByUser": this.lastUpdateByUser || null,
    }
  }
}

export function mapMessages(message) {
  return new MessageModel(
    message.data(),
    message.ref,
  )
}
