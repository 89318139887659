import { errorDefault } from "../helpers/snackbar"
import { mapPromoCodeStatistic } from "../models/PromoCodeStatisticModel"
import { getPromoCodeStatisticsCollection } from "../firebase/firestorePromoCodeStatistics"
import store from "."

function onErrorShared(error) {
  console.error(error)
  store.dispatch(
    "snackbar/showSnackbar",
    errorDefault(error),
  )
}

function initialState() {
  return {
    "data": null,

    "fetchLoading": false,
    "fetchError": null,
  }
}

export default {
  "namespaced": true,
  "state": initialState(),
  "actions": {
    resetState({ commit }) {
      commit("RESET_STATE")
    },

    getPromoCodeStatistic({
      commit,
      "rootState": {
        "app": {
          "userData": { company },
        },
      },
    }, {
      startDate,
      endDate,
    }) {
      commit("GET_PROMO_CODE_STATISTICS_REQUEST")

      const onSuccess = ({ docs, size }) => {
        commit(
          "GET_PROMO_CODE_STATISTICS_SUCCESS",
          {
            "data": docs.map(mapPromoCodeStatistic),
            size,
          },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          "GET_PROMO_CODE_STATISTICS_FAILURE",
          error,
        )
        onErrorShared(error)
      }

      getPromoCodeStatisticsCollection(
        company,
        startDate,
        endDate,
      )
        .then(onSuccess)
        .catch(onError)
    },
  },
  "mutations": {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    GET_PROMO_CODE_STATISTICS_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    GET_PROMO_CODE_STATISTICS_SUCCESS(_state, { data, last, size }) {
      _state.size = size
      _state.lastVisible = last
      _state.fetchLoading = false
      _state.fetchError = null
      if (_state.data) {
        _state.data = [
          ..._state.data,
          ...data,
        ]
      }
      else {
        _state.data = data
      }
    },
    GET_PROMO_CODE_STATISTICS_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchError = error
    },
  },
}
