import type { DocumentReference } from "firebase/firestore"
import { OnlineTransactionReports } from "./OnlineTransactionReportsModel"

export class OrdersStatistic {
  city: string | null
  company: DocumentReference | null
  date: string | null
  homeOrders: string | null
  officeOrders: string | null
  onlineTransactionReports: OnlineTransactionReports | null
  soldOutDishes: string | null
  notSoldOutDishes: string | null
  reference: DocumentReference | null

  constructor(data, reference?) {
    this.city = data.city || null
    this.company = data.company || null
    this.date = data.date || null
    this.homeOrders = data.homeOrders || null
    this.officeOrders = data.officeOrders || null

    this.onlineTransactionReports = data?.onlineTransactionReports
      ? new OnlineTransactionReports(data.onlineTransactionReports)
      : null

    this.soldOutDishes = data.soldOutDishes
    this.notSoldOutDishes = data.notSoldOutDishes

    this.reference = reference
  }

  toMap() {
    return {
      "city": this.city,
      "company": this.company,
      "date": this.date,
      "homeOrders": this.homeOrders,
      "officeOrders": this.officeOrders,
      "onlineTransactionReports": this.onlineTransactionReports,
      "soldOutDishes": this.soldOutDishes,
      "notSoldOutDishes": this.notSoldOutDishes,
    }
  }
}

export function mapOrdersStatistic(data) {
  return new OrdersStatistic(
    data.data(),
    data.ref,
  )
}
