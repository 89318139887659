import { captureException } from "@sentry/browser"

function initialState() {
  return {
    "loading": false,
    "error": null,
  }
}

export default {
  "namespaced": true,
  "state": initialState(),
  "actions": {
    resetState({ commit }) {
      commit("RESET_STATE")
    },

    setLoading({ commit }, payload) {
      commit(
        "SET_LOADING",
        payload,
      )
    },
    clearError({ commit }) {
      commit("CLEAR_ERROR")
    },
    setError({ commit }, error) {
      captureException(error)
      commit(
        "SET_ERROR",
        error,
      )
    },

    init({ commit }) {
      commit("CLEAR_ERROR")
      commit(
        "SET_LOADING",
        true,
      )
    },
    success({ commit }) {
      commit(
        "SET_LOADING",
        false,
      )
    },
    error({ commit }, error) {
      commit(
        "SET_ERROR",
        error,
      )
      commit(
        "SET_LOADING",
        false,
      )
    },
  },
  "mutations": {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_ERROR(state, payload) {
      state.error = payload
    },
    CLEAR_ERROR(state) {
      state.error = null
    },
  },
}
