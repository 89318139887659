import { serverTimestamp } from "firebase/firestore"

export function onCreate(userDataReference) {
  return {
    "createdByUser": userDataReference,
    "lastUpdateByUser": userDataReference,
    "lastUpdateTime": serverTimestamp(),
  }
}

export function onUpdate(userDataReference) {
  return {
    "lastUpdateByUser": userDataReference,
    "lastUpdateTime": serverTimestamp(),
  }
}
