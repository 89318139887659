export const textHours = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
]

const snadwiczShopCompnayId = "matOdfniTnCwFxB2W3Ch"
const stagingCompanyId = "3bauh5VCndobLMMZlLhV"
const kateringProductionId = "Sr6xvJaRagdPM5HGVydC"

const ekoCatering = "ot4P4FdAQMmZ5z2MhUZo"
const ekoCateringStaging = "vh0L3QCx9VDuXLCEEFze"

export function isSandwiczSzop(id) {
  return id === snadwiczShopCompnayId
    || id === stagingCompanyId
    || id === kateringProductionId
}

export function isEkoCatering(id) {
  return id === ekoCatering || id === ekoCateringStaging
}
