export interface ICompaniesModel {
  companyName: string | null
  floor: string | null
  phone: string | null
  email: string | null
  note: string | null
  contactName: string | null
}

export class CompaniesModel {
  companyName: string | null
  floor: string | null
  phone: string | null
  email: string | null
  note: string | null
  contactName: string | null

  constructor(data: any) {
    this.companyName = data.companyName || null
    this.floor = data.floor || null
    this.phone = data.phone || null
    this.email = data.email || null
    this.note = data.note || null
    this.contactName = data.contactName || null
  }

  toMap() {
    return {
      "companyName": this.companyName,
      "floor": this.floor,
      "phone": this.phone,
      "email": this.email,
      "note": this.note,
      "contactName": this.contactName,
    }
  }
}
