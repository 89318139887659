import "dayjs/locale/pl"
import { Timestamp } from "firebase/firestore"
import dayjs from "dayjs"

const divider = ":"

export function mapTimeToFirebaseFormat(time) {
  if (!time)
    return null

  const [
    hours,
    minutes,
  ] = time.split(divider)
  const date = dayjs().hour(hours)
    .minute(minutes)
    .toDate()
  return Timestamp.fromDate(date)
}

export const now = Timestamp.now()

export const mapDateToFirebaseFormat = date => Timestamp.fromDate(date)

export const mapDateAndTimeFromFirebaseFormat = (date, time) => Timestamp.fromDate(dayjs(`${date} ${time}`).toDate())

export function getFirstDayOfMonth(selectedDate) {
  return mapDateToFirebaseFormat(selectedDate.date(1)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate())
}

export function getFirstDayOfNextMonth(selectedDate) {
  return mapDateToFirebaseFormat(selectedDate.add(
    1,
    "month",
  ).date(1)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate())
}

export function mapTimestampToDateString(date) {
  const newDate = date.toDate()
  const year = newDate.getFullYear()
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0")
  const day = newDate.getDate().toString().padStart(2, "0")
  return `${year}-${month}-${day}`
}
