import { collection, getDocs, query, serverTimestamp, updateDoc, where } from "firebase/firestore"
import { firestore } from "../firebaseCore"

export const missingCompaniesCollection = collection(
  firestore,
  "missingCompanies",
)

export function getMissingCompanies(companyRef) {
  return getDocs(query(
    missingCompaniesCollection,
    where(
      "companyRef",
      "==",
      companyRef,
    ),
  ))
}

export function updatetMissingCompany(item, status, userData) {
  return updateDoc(
    item.reference,
    {
      status,
      "lastUpdateByUser": userData.reference,
      "lastUpdateTime": serverTimestamp(),
    },
  )
}
