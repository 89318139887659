import { addDoc, getDocs, increment, onSnapshot, serverTimestamp, updateDoc, writeBatch } from "firebase/firestore"
import {
  collectionRoutes,
  getRoutes,
  getRoutesByCities,
  ordersToUpdate,
} from "../firestoreWrappers"
import { isBiller, isGetDeliver, isManagerOrDeliver, isOwner } from "../helpers/roles"
import { errorDefault } from "../helpers/snackbar"
import { firestore } from "../firebaseCore"
import { mapRoute } from "../models/RouteModel"
import { getDeliversSellsLast2Months } from "./firestoreDeliversSells"
import store from "."

function initialState() {
  return {
    "routes": null,

    "fetchLoading": false,
    "fetchError": null,

    "createLoading": false,
    "createError": null,

    "updateLoading": false,
    "updateError": null,
    "unsubscribe": null,
  }
}

export default {
  "namespaced": true,
  "state": initialState(),
  "actions": {
    resetState({
      commit,
      "rootState": {
        "routes": { unsubscribe },
      },
    }) {
      if (unsubscribe)
        unsubscribe()

      commit("RESET_STATE")
    },

    getRoutes({
      commit,
      "rootState": {
        "app": {
          "userData": {
            company,
            "profile": { cities },
            reference,
            role,
          },
          "user": { displayName },
        },
        "routes": { unsubscribe },
      },
    }) {
      commit("GET_ROUTES_REQUEST")

      if (unsubscribe) {
        unsubscribe()
        commit(
          "SAVE_UNSUBSCRIBE",
          null,
        )
      }

      const onSuccess = ({ docs }) => {
        const byName = (item1, item2) => item2?.name - item1?.name
        let data = docs.map(mapRoute).sort(byName)
        if (isGetDeliver(role) || isBiller(role))
          data = (data || []).filter(item => (item?.visibilityAccess || []).map(item1 => item1?.id).includes(reference.id))

        commit(
          "GET_ROUTES_SUCCESS",
          data,
        )
      }

      const onError = (error) => {
        console.error(error)
        if (error.code !== "permission-denied") {
          commit(
            "GET_ROUTES_FAILURE",
            error,
          )
          store.dispatch(
            "snackbar/showSnackbar",
            errorDefault(error),
          )
        }
      }

      if (isOwner(displayName) || isManagerOrDeliver(displayName)) {
        const query = isOwner(displayName)
          ? getRoutes(company)
          : getRoutesByCities(
            company,
            cities,
          )

        const unsubscribeNew = onSnapshot(
          query,
          onSuccess,
          onError,
        )

        commit(
          "SAVE_UNSUBSCRIBE",
          unsubscribeNew,
        )
      }
      else {
        onError("Wrong site")
      }
    },
    createRoute(
      {
        commit,
        "rootState": {
          "app": {
            "userData": { company, reference },
          },
        },
      },
      { "route": routeInput },
    ) {
      commit("CREATE_ROUTES_REQUEST")

      const route = routeInput
      route.company = company
      route.lastUpdateByUser = reference
      route.lastUpdateTime = serverTimestamp()

      const onSuccess = (ref) => {
        route.reference = ref
        commit(
          "CREATE_ROUTES_SUCCESS",
          { route },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          "CREATE_ROUTES_FAILURE",
          error,
        )
      }

      addDoc(
        collectionRoutes,
        {
          ...route.toMap(),
          "createdByUser": reference,
        },
      ).then(onSuccess)
        .catch(onError)
    },
    async updateRoute(
      {
        commit,
        "rootState": {
          "app": {
            "userData": { company, reference },
          },
          "routes": { routes },
          "suppliers": { suppliers },
        },
      },
      { "route": routeInput, deliver, subDeliver, changeDeliver },
    ) {
      commit("UPDATE_ROUTES_REQUEST")
      const route = routeInput

      route.company = company
      route.lastUpdateByUser = reference
      route.lastUpdateTime = serverTimestamp()

      const batch = writeBatch(firestore)

      batch.update(
        route.reference,
        {
          ...route.toMapFirebase(),
          "lastUpdateByUser": reference,
          "lastUpdateTime": serverTimestamp(),

        },
      )

      const oldRoute = routes.find(item => item.reference.id === route.reference.id)
      const added = route.visibilityAccess.filter(item => !oldRoute.visibilityAccess.includes(item))
      const removed = oldRoute.visibilityAccess.filter(item => !route.visibilityAccess.includes(item))

      const itemsToUpdate = await getDeliversSellsLast2Months(
        company,
        route.reference,
      )

      if (!itemsToUpdate.empty) {
        itemsToUpdate.docs.forEach((item) => {
          let dataToUpdate = item.data().visibilityAccess.filter(item4 => item4) || []
          if (added.length > 0) {
            dataToUpdate = [
              ...dataToUpdate,
              ...added,
            ]
          }
          if (removed.length > 0) {
            const tmp = removed.map(item2 => item2.id)
            dataToUpdate = dataToUpdate.filter(item3 => !tmp.includes(item3.id))
          }
          const result = [...new Set(dataToUpdate)] || []
          batch.update(
            item.ref,
            {
              "visibilityAccess": result,
            },
          )
        })
      }

      const updateDeliverId = (supplier) => {
        if (supplier && supplier.reference) {
          const deliverID = route.isSubDeliver
            ? subDeliver.reference
            : deliver.reference

          batch.update(
            supplier.reference,
            {
              deliverID,
              "lastUpdateByUser": reference,
              "lastUpdateTime": serverTimestamp(),
            },
          )
        }
      }

      if (suppliers)
        suppliers.forEach(updateDeliverId)

      const onError = (error) => {
        console.error(error)
        commit(
          "UPDATE_ROUTES_FAILURE",
          error,
        )
      }

      const onSuccess = () => {
        commit(
          "UPDATE_ROUTES_SUCCESS",
          { route },
        )
      }

      const onSuccess1 = ({ docs }) => {
        const mapOrders = (order) => {
          batch.update(
            order.ref,
            {
              "deliver": changeDeliver,
              "lastUpdateByUser": reference,
              "lastUpdateTime": serverTimestamp(),
            },
          )
        }
        docs.map(mapOrders)

        batch.commit()
          .then(onSuccess)
          .catch(onError)
      }

      if (changeDeliver) {
        getDocs(ordersToUpdate(
          company,
          route.reference,
        ))
          .then(onSuccess1)
          .catch(onError)
      }
      else {
        batch.commit()
          .then(onSuccess)
          .catch(onError)
      }
    },
    decreasePendingOrders(
      {
        commit,
        "rootState": {
          "routes": { routes },
          "app": { userData },
        },
      },
      route,
    ) {
      const onSuccess = () => {
        const updateOnlySelectedRoute = (routeData) => {
          if (routeData.reference.id === route.reference.id) {
            const tmpRoute = routeData
            tmpRoute.pendingOrders -= 1
            return tmpRoute
          }
          return routeData
        }
        const mappedRoutes = routes.map(updateOnlySelectedRoute)
        commit(
          "UPDATE_ROUTES_COUNTERS_SUCCESS",
          { "routes": mappedRoutes },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          "UPDATE_ROUTES_COUNTERS_FAILURE",
          error,
        )
      }

      updateDoc(
        route.reference,
        {
          "pendingOrders":
          increment(-1),
          "lastUpdateByUser": userData.reference,
          "lastUpdateTime": serverTimestamp(),
        },
      )
        .then(onSuccess)
        .catch(onError)
    },
    updateRouteData({ commit }, routes) {
      commit(
        "GET_ROUTES_SUCCESS",
        routes,
      )
    },
  },
  "mutations": {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    SAVE_UNSUBSCRIBE(state, unsubscribe) {
      state.unsubscribe = unsubscribe
    },

    GET_ROUTES_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    GET_ROUTES_SUCCESS(_state, routes) {
      _state.fetchLoading = false
      _state.fetchError = null
      _state.routes = routes
    },
    GET_ROUTES_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchError = error
    },

    CREATE_ROUTES_REQUEST(_state) {
      _state.createError = null
      _state.createLoading = true
    },
    CREATE_ROUTES_SUCCESS(_state) {
      _state.createLoading = false
      _state.createError = null
    },
    CREATE_ROUTES_FAILURE(_state, error) {
      _state.createLoading = false
      _state.createError = error
    },

    UPDATE_ROUTES_REQUEST(_state) {
      _state.updateError = null
      _state.updateLoading = true
    },
    UPDATE_ROUTES_SUCCESS(_state, { route }) {
      _state.updateLoading = false
      _state.updateError = null

      const routeId = route.reference.id
      const mapRouteData = ({ "reference": { id } }) => id == routeId
      const routeIndex = _state.routes.findIndex(mapRouteData)
      _state.routes[routeIndex] = route
    },
    UPDATE_ROUTES_FAILURE(_state, error) {
      _state.updateLoading = false
      _state.updateError = error
    },

    UPDATE_ROUTES_COUNTERS_SUCCESS(_state, { routes }) {
      _state.routes = routes
    },
    UPDATE_ROUTES_COUNTERS_FAILURE() {},
  },
}
