import type { DocumentReference } from "firebase/firestore"
import { mapDateFromFirebaseFormat } from "../helpers/time"
import i18n from "~/i18n"

export class MissingCompanyModel {
  date: string | null
  company: DocumentReference | null
  city: string | null
  address: string | null
  phone: string | null
  postCode: string | null
  message: string | null
  companyRef: string | null
  status: string | null
  reference: DocumentReference | null

  constructor(data, reference?) {
    this.date = mapDateFromFirebaseFormat(data.date) || null
    this.company = data.company || null
    this.city = data.city || null
    this.address = data.address || null
    this.phone = data.phone || null
    this.postCode = data.postCode || null
    this.message = data.message || null
    this.companyRef = data.companyRef || null
    this.status = data.status || i18n.t("MessagesView.active")
    this.reference = reference || null
  }

  toMap() {
    return {
      "date": this.date,
      "company": this.company,
      "city": this.city,
      "address": this.address,
      "phone": this.phone,
      "postCode": this.postCode,
      "message": this.message?.trim() || null,
      "companyRef": this.companyRef?.trim() || null,
      "status": this.status,
    }
  }
}

export function mapMissingCompany(message) {
  return new MissingCompanyModel(
    message.data(),
    message.ref,
  )
}
