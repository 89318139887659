import { OrdersRatingModel } from "./OrdersRatingModel"
import { OrderSourceModel } from "./OrderSourceModel"

export class OrdersInfoModel {
  delivered: string | null
  completed: string | null
  completedAmountPrice: string | null
  waitingCustomerAccept: string | null
  waitingFirmAccept: string | null
  cancelled: string | null
  rejected: string | null
  packaged: string | null
  waitingPackaging: string | null
  failed: string | null
  notGivenOrderTime: string | null
  acceptNotPreferableOrderTime: string | null
  orderSource: OrderSourceModel
  ordersRating: OrdersRatingModel

  constructor(data) {
    this.delivered = data?.delivered || 0
    this.completed = data?.completed || 0
    this.completedAmountPrice = data?.completedAmountPrice || 0
    this.waitingCustomerAccept = data?.waitingCustomerAccept || 0
    this.waitingFirmAccept = data?.waitingFirmAccept || 0
    this.cancelled = data?.cancelled
    this.rejected = data?.rejected || 0
    this.packaged = data?.packaged || 0
    this.waitingPackaging = data?.waitingPackaging || 0
    this.failed = data?.failed || 0
    this.notGivenOrderTime = data?.notGivenOrderTime || 0
    this.acceptNotPreferableOrderTime = data?.acceptNotPreferableOrderTime || 0
    this.orderSource = new OrderSourceModel(data?.orderSource)
    this.ordersRating = new OrdersRatingModel(data?.ordersRating)
  }

  toMap() {
    return {
      "delivered": this.delivered,
      "completed": this.completed,
      "completedAmountPrice": this.completedAmountPrice,
      "packaged": this.packaged,
      "waitingPackaging": this.waitingPackaging,
      "waitingCustomerAccept": this.waitingCustomerAccept,
      "waitingFirmAccept": this.waitingFirmAccept,
      "rejected": this.rejected,
      "cancelled": this.cancelled,
      "failed": this.failed,
      ...this.orderSource.toMap(),
      ...this.ordersRating.toMap(),
    }
  }

  toMapHome() {
    return {
      ...this.toMap(),
      "notGivenOrderTime": this.notGivenOrderTime,

    }
  }

  toMapOffice() {
    return {
      ...this.toMap(),
      "acceptNotPreferableOrderTime": this.acceptNotPreferableOrderTime,
    }
  }
}

export const mapOrdersStatistic = data => new OrdersInfoModel(data)
