import { Timestamp, collection, doc, getDoc, orderBy, query, where } from "firebase/firestore"
import { PAYMENT_METHODS_SUBSIDY } from "./store/orders"
import { firestore } from "~/firebaseCore"
import { getIdFromRef } from "~/helpers/getIdFromRef"
import { removePolishLetters } from "~/helpers/text"
import { ROLE } from "~/helpers/roles"

export const collectionNotificationRaports = collection(
  firestore,
  "notificationRaports",
)
export const collectionMenu = collection(
  firestore,
  "menu",
)
export const collectionRoutes = collection(
  firestore,
  "routes",
)
export const collectionMenuCategories = collection(
  firestore,
  "menuCategories",
)
export const collectionOrders = collection(
  firestore,
  "orders",
)
export const collectionUsers = collection(
  firestore,
  "users",
)
export const collectionManagers = collection(
  firestore,
  "managers",
)
export const collectionDelivers = collection(
  firestore,
  "delivers",
)
export const collectionOwners = collection(
  firestore,
  "owners",
)
export const collectionModerators = collection(
  firestore,
  "moderators",
)
export const collectionSMS = collection(
  firestore,
  "sms",
)
export const collectionStatisticAccess = collection(
  firestore,
  "statisticAccess",
)
export const collectionCompanies = collection(
  firestore,
  "companies",
)
export const collectionUpdateApplication = collection(
  firestore,
  "updateApplication",
)
export const collectionMenuReturn = collection(
  firestore,
  "menuReturns",
)
export function promoCodeTermsQuery() {
  return getDoc(doc(
    collectionUpdateApplication,
    "promoCodeTerms",
  ))
}

export function recommendationProgramQuery() {
  return getDoc(doc(
    collectionUpdateApplication,
    "recommendationProgram",
  ))
}

export const collectionSupplier = collection(
  firestore,
  "supplier",
)
export const collectionOrdersStatistic = collection(
  firestore,
  "ordersStatistics",
)
export const reportBugOrSuggestionCollection = collection(
  firestore,
  "reportBugOrSuggestion",
)

export function getOwner(uid) {
  return getDoc(doc(
    collectionOwners,
    uid,
  ))
}

export function getDeliver(uid) {
  return getDoc(doc(
    collectionDelivers,
    uid,
  ))
}
export function getManager(uid) {
  return getDoc(doc(
    collectionManagers,
    uid,
  ))
}
export function getModerator(uid) {
  return getDoc(doc(
    collectionModerators,
    uid,
  ))
}
export function getCustomer(uid) {
  return getDoc(doc(
    collectionUsers,
    uid,
  ))
}

export function getLandingCompanies() {
  return query(
    collectionCompanies,
    where(
      "isActive",
      "==",
      true,
    ),
  )
}

export function statisticAccessDoc(companyRef) {
  return getDoc(doc(
    collectionStatisticAccess,
    companyRef),
  )
}

export function deliver({ company }) {
  return query(
    collectionDelivers,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "role",
      "==",
      ROLE.DELIVER,
    ),
  )
}

export function getdeliver({ company }) {
  return query(
    collectionDelivers,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "role",
      "==",
      ROLE.GET_DELIVER,
    ),
  )
}

export function getBillers({ company }) {
  return query(
    collectionDelivers,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "role",
      "==",
      ROLE.BILLER,
    ),
  )
}

export function getActiveDelivers(companyRef) {
  return query(
    collectionDelivers,
    where(
      "company",
      "==",
      companyRef,
    ),
    where(
      "isActive",
      "==",
      true,
    ),
  )
}

export function manager({ company }) {
  return query(
    collectionManagers,
    where(
      "company",
      "==",
      company,
    ),
  )
}

export function owner({ company }) {
  return query(
    collectionOwners,
    where(
      "company",
      "==",
      company,
    ),
  )
}

export function menuByCategoryRef(companyRef, menuCategoryID, isActive) {
  return query(
    collectionMenu,
    where(
      "companyRef",
      "==",
      companyRef,
    ),
    where(
      "categoryID",
      "==",
      menuCategoryID,
    ),
    where(
      "isActive",
      "==",
      isActive,
    ),
  )
}

export function getMenuByDay(companyRef, day) {
  return query(
    collectionMenu,
    where(
      "companyRef",
      "==",
      companyRef,
    ),
    where(
      "isActive",
      "==",
      true,
    ),
    where(
      "activeDays",
      "array-contains",
      day,
    ),
  )
}

export function getRoutes(company) {
  return query(
    collectionRoutes,
    where(
      "company",
      "==",
      company,
    ),
  )
}

export function getRoutesByCities(company, cities) {
  return query(
    collectionRoutes,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "city",
      "in",
      cities,
    ),
  )
}

export function menuCategoriesRef(company) {
  return doc(
    collectionMenuCategories,
    getIdFromRef(company),
  )
}

export function ordersDate(company, dateStart, dateEnd, route) {
  return query(
    collectionOrders,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "route",
      "==",
      route,
    ),
    where(
      "date",
      ">=",
      dateStart,
    ),
    where(
      "date",
      "<",
      dateEnd,
    ),
  )
}

export function ordersSubsidy(company, dateStart, dateEnd, supplierRef) {
  return query(
    collectionOrders,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "paymentMethod",
      "in",
      PAYMENT_METHODS_SUBSIDY,
    ),
    where(
      "completementStatus",
      "==",
      "completed",
    ),
    where(
      "supplier",
      "==",
      supplierRef,
    ),
    where(
      "date",
      ">",
      dateStart,
    ),
    where(
      "date",
      "<",
      dateEnd,
    ),
  )
}

export function ordersOnlinePayment(company, dateStart, dateEnd) {
  return query(
    collectionOrders,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "paymentStatus",
      "in",
      [
        "success",
        "partialRefund",
      ],
    ),
    where(
      "date",
      ">",
      dateStart,
    ),
    where(
      "date",
      "<",
      dateEnd,
    ),
  )
}

export function ordersSalesPercentage(company, dateStart, dateEnd, data) {
  return query(
    collectionOrders,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "completementStatus",
      "==",
      "completed",
    ),
    where(
      "salesPercentage",
      "in",
      data,
    ),
    where(
      "date",
      ">",
      dateStart,
    ),
    where(
      "date",
      "<",
      dateEnd,
    ),
  )
}

export function ordersCompletedDateRange(company, dateStart, dateEnd) {
  return query(
    collectionOrders,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "completementStatus",
      "==",
      "completed",
    ),
    where(
      "date",
      ">",
      dateStart,
    ),
    where(
      "date",
      "<",
      dateEnd,
    ),
  )
}

export function ordersFixedSales(company, dateStart, dateEnd) {
  return query(
    collectionOrders,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "completementStatus",
      "==",
      "completed",
    ),
    where(
      "isSales",
      "==",
      true,
    ),
    where(
      "date",
      ">",
      dateStart,
    ),
    where(
      "date",
      "<",
      dateEnd,
    ),
  )
}

export function ordersSalesPercentageSecond(company, dateStart, dateEnd) {
  return query(
    collectionOrders,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "completementStatus",
      "==",
      "completed",
    ),
    where(
      "salesPercentage",
      "in",
      [
        0.5,
        0.55,
        0.6,
        0.65,
        0.7,
        0.75,
        0.8,
        0.85,
        0.9,
        0.95,
      ],
    ),
    where(
      "date",
      ">",
      dateStart,
    ),
    where(
      "date",
      "<",
      dateEnd,
    ),
  )
}

export function ordersLoyaltyProgram(company, dateStart, dateEnd) {
  return query(
    collectionOrders,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "completementStatus",
      "==",
      "completed",
    ),
    where(
      "isLoyaltyProgram",
      "==",
      true,
    ),
    where(
      "date",
      ">",
      dateStart,
    ),
    where(
      "date",
      "<",
      dateEnd,
    ),
  )
}

export function ordersIsNewUser(company, dateStart, dateEnd) {
  return query(
    collectionOrders,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "completementStatus",
      "==",
      "completed",
    ),
    where(
      "orderByRecommendation.isNewUser",
      "==",
      true,
    ),
    where(
      "date",
      ">",
      dateStart,
    ),
    where(
      "date",
      "<",
      dateEnd,
    ),
  )
}

export function ordersToUpdate(company, reference) {
  return query(
    collectionOrders,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "route",
      "==",
      reference,
    ),
    where(
      "progress",
      "==",
      "processing",
    ),
  )
}

export function ordersStatistic(company, dateStart, dateEnd) {
  return query(
    collectionOrders,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "completementStatus",
      "in",
      [
        "completed",
        "waitingFirmAccept",
        "waitingCustomerAccept",
      ],
    ),
    where(
      "date",
      ">=",
      dateStart,
    ),
    where(
      "date",
      "<",
      dateEnd,
    ),
  )
}

export function currentOrdersRef(supplier) {
  return query(
    collectionOrders,
    where(
      "supplier",
      "==",
      supplier.reference,
    ),
    where(
      "company",
      "==",
      supplier.company,
    ),
    where(
      "progress",
      "==",
      "processing",
    ),
  )
}

export function getLockedUsers(supplier) {
  return query(
    collectionUsers,
    where(
      "work.suppliers",
      "array-contains",
      supplier.reference,
    ),
    where(
      "ordersAvailable",
      "==",
      false,
    ),
  )
}

export function getUsersBySuppliers(supplier) {
  return query(
    collectionUsers,
    where(
      "work.suppliers",
      "array-contains",
      supplier.ref,
    ),
  )
}

export function getSuppliersByDeliver(deliverData) {
  return query(
    collectionSupplier,
    where(
      "deliverID",
      "==",
      deliverData.reference,
    ),
  )
}

export function getSuppliiersByCode(code) {
  return query(
    collectionSupplier,
    where(
      "code",
      "==",
      `${code}`,
    ),
  )
}

export function getSuppliersByCompanyRouteCity(company, routeRef, cities) {
  return query(
    collectionSupplier,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "route",
      "==",
      routeRef,
    ),
    where(
      "place.city",
      "in",
      cities,
    ),
  )
}

export function getSuppliersSearcheCity(company, cities, search) {
  return query(
    collectionSupplier,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "tags",
      "array-contains-any",
      [
        ...search,
        ...(cities.map(removePolishLetters)),
      ],
    ),
  )
}

export function getSuppliersSubsidyByCompanyRouteCity(company, cities) {
  return query(
    collectionSupplier,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "subsidy",
      "!=",
      null,
    ),
    where(
      "place.city",
      "in",
      cities,
    ),
  )
}
export function getNotSentNotificationSuppliersByCompanyCity(company, cities) {
  return query(
    collectionSupplier,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "isPublicOffice",
      "==",
      true,
    ),
    where(
      "isActive",
      "==",
      true,
    ),
    where(
      "notificationInfo.notificationCount",
      "==",
      0,
    ),
    where(
      "place.city",
      "in",
      cities,
    ),
  )
}

export function getNotSentNotificationSuppliersByCompanyCity2(company, cities) {
  return query(
    collectionSupplier,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "isHomeDelivery",
      "==",
      false,
    ),
    where(
      "isActive",
      "==",
      true,
    ),
    where(
      "notificationInfo.notificationCount",
      "==",
      0,
    ),
    where(
      "place.city",
      "in",
      cities,
    ),
  )
}

export function getNotSentNotificationSuppliersByCompany(company) {
  return query(
    collectionSupplier,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "isPublicOffice",
      "==",
      true,
    ),
    where(
      "isActive",
      "==",
      true,
    ),
    where(
      "notificationInfo.notificationCount",
      "==",
      0,
    ),
  )
}

export function getNotSentNotificationSuppliersByCompany2(company) {
  return query(
    collectionSupplier,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "isHomeDelivery",
      "==",
      false,
    ),
    where(
      "isActive",
      "==",
      true,
    ),
    where(
      "notificationInfo.notificationCount",
      "==",
      0,
    ),
  )
}

export function getNotificationsByRouteDate(routeRef, dateFrom, dateTo) {
  return query(
    collectionNotificationRaports,
    where(
      "route",
      "==",
      routeRef,
    ),
    where(
      "sendDate",
      ">=",
      Timestamp.fromDate(dateFrom),
    ),
    where(
      "sendDate",
      "<",
      Timestamp.fromDate(dateTo),
    ),
    orderBy("sendDate", "desc"),
  )
}

export function getSuppliersByCompanyRoute(company, routeRef) {
  return query(
    collectionSupplier,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "route",
      "==",
      routeRef,
    ),
  )
}

export function getSuppliersSearch(company, search) {
  return query(
    collectionSupplier,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "tags",
      "array-contains-any",
      search,
    ),
  )
}

export function getSuppliersSubsidyByCompanyRoute(company) {
  return query(
    collectionSupplier,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "subsidy",
      "!=",
      null,
    ),
  )
}

export function getSuppliersSubsidyByRoute(routeRef) {
  return query(
    collectionSupplier,
    where(
      "route",
      "==",
      routeRef,
    ),
    orderBy(
      "arrivalTime",
      "desc",
    ),
  )
}

export function getSupplierFromHomeDeliveryCity(company, cities) {
  return query(
    collectionSupplier,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "isHomeDelivery",
      "==",
      true,
    ),
    where(
      "place.city",
      "in",
      cities,
    ),
  )
}

export function getSupplierFromHomeDelivery(company) {
  return query(
    collectionSupplier,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "isHomeDelivery",
      "==",
      true,
    ),
  )
}

export function getCollectionOrdersStatistic(company, startDate, endDate, city) {
  return query(
    collectionOrdersStatistic,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "city",
      "==",
      city,
    ),
    where(
      "date",
      ">=",
      startDate,
    ),
    where(
      "date",
      "<",
      endDate,
    ),
  )
}
export function getSingleOrdersStatistic(company, city, date) {
  return query(
    collectionOrdersStatistic,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "city",
      "==",
      city,
    ),
    where(
      "date",
      "==",
      date,
    ),
  )
}

export function getReportBugOrSuggestionActive(company) {
  return query(
    reportBugOrSuggestionCollection,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "status",
      "in",
      [
        "active",
        "solved",
        "waitingCustomerAnswer",
        "toDo",
        "inProgress",
        "done",
      ],
    ),
  )
}

export function getReportBugOrSuggestionActiveByCitiesActive(company, cities) {
  return query(
    reportBugOrSuggestionCollection,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "place.city",
      "in",
      cities,
    ),
    where(
      "status",
      "==",
      "active",
    ),
  )
}

export function getReportBugOrSuggestionActiveByCitiesSolved(company, cities) {
  return query(
    reportBugOrSuggestionCollection,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "place.city",
      "in",
      cities,
    ),
    where(
      "status",
      "==",
      "solved",
    ),
  )
}
export function getReportBugOrSuggestionActiveByCitiesWaitingCustomerAnswer(company, cities) {
  return query(
    reportBugOrSuggestionCollection,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "place.city",
      "in",
      cities,
    ),
    where(
      "status",
      "==",
      "waitingCustomerAnswer",
    ),
  )
}

export function getReportWithoutCities(company) {
  return query(
    reportBugOrSuggestionCollection,
    where(
      "company",
      "==",
      company,
    ),
    where(
      "place",
      "==",
      null,
    ),
    where(
      "status",
      "in",
      [
        "active",
        "solved",
        "waitingCustomerAnswer",
      ],
    ),
  )
}
