import { defineStore } from "pinia"
import { ref } from "vue"

export const useDrawerStore = defineStore("drawer", () => {
  const visible = ref(true)
  const visibleLanding = ref(false)

  function toggleDrawer() {
    visible.value = !visible.value
  }
  function setDrawer(value) {
    visible.value = value
  }

  function toggleDrawerLanding() {
    visibleLanding.value = !visibleLanding.value
  }

  return {
    visible,
    visibleLanding,
    setDrawer,

    toggleDrawer,
    toggleDrawerLanding,
  }
})
