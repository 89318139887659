import { collection, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore"
import { firestore } from "../firebaseCore"

export const collectionCoupons = collection(
  firestore,
  "coupons",
)

export function getCouponsCollection(company) {
  return getDocs(query(
    collectionCoupons,
    where(
      "companyRef",
      "==",
      company,
    ),
    where(
      "isActive",
      "==",
      true,
    ),
  ))
}

export function getNextCouponsCollection(company, lastVisible) {
  return getDocs(query(
    collectionCoupons,
    where(
      "company",
      "==",
      company,
    ),
    orderBy(
      "publishDay",
      "desc",
    ),
    startAfter(lastVisible),
    limit(5),
  ))
}

export function checkCouponCode(promoCode) {
  return getDocs(query(
    collectionCoupons,
    where(
      "promoCode",
      "==",
      promoCode,
    ),
  ))
}
