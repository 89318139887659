import type { GeoPoint, Timestamp } from "firebase/firestore"
import { DocumentModel } from "./DocumentModel"

interface IWork {
  arrivalTime: Timestamp
  address: string
  city: string
  description: string
  company: string
  employeesCount: number
  flor: number
  geoPoint: GeoPoint | null
  geoHash: any
  parking: boolean
}

export class CustomerModel extends DocumentModel {
  appVersion: string
  dislikeDishes: any[]
  displayName: string
  email: string
  facebookUid: any
  favouriteDishes: any[]
  home: any
  isActive: boolean
  languageCode: string
  notificationPlayerId: any
  notificationSettings: any
  notificationToken: any
  ordersAvailable: boolean
  paymentId: any
  phone: string
  preferences: any[]
  role: string
  work: IWork
  subsidyBudget: any
  isOrderWithSubsidy: boolean

  constructor(data, reference) {
    super(
      data,
      reference,
    )

    this.appVersion = data.appVersion || ""
    this.dislikeDishes = data.dislikeDishes || []
    this.displayName = data.displayName || ""
    this.email = data.email || ""
    this.facebookUid = data.facebookUid || null
    this.favouriteDishes = data.favouriteDishes || []
    this.home = data.home || null
    this.isActive = data.isActive || false
    this.languageCode = data.languageCode || "pl"
    this.notificationPlayerId = data.notificationPlayerId || null
    this.notificationSettings = data.notificationSettings || null
    this.notificationToken = data.notificationToken || null
    this.ordersAvailable = data.ordersAvailable || false
    this.paymentId = data.paymentId || null
    this.phone = data.phone || ""
    this.preferences = data.preferences || []
    this.role = data.role || ""
    this.work = data.work || null
    this.subsidyBudget = data.subsidyBudget === null || data.subsidyBudget === undefined ? null : data.subsidyBudget
    this.isOrderWithSubsidy = data.isOrderWithSubsidy || false
  }

  toMap() {
    return {
      ...super.toMap(),

      "appVersion": this.appVersion,
      "dislikeDishes": this.dislikeDishes,
      "displayName": this.displayName,
      "email": this.email,
      "facebookId": this.facebookUid,
      "favouriteDishes": this.favouriteDishes,
      "home": this.home,
      "isActive": this.isActive,
      "languageCode": this.languageCode,
      "notificationPlayerId": this.notificationPlayerId,
      "notificationSettings": this.notificationSettings,
      "notificationToken": this.notificationToken,
      "ordersAvailable": this.ordersAvailable,
      "paymentId": this.paymentId,
      "phone": this.phone,
      "preferences": this.preferences,
      "role": this.role,
      "work": this.work,
      "subsidyBudget": this.subsidyBudget,
      "isOrderWithSubsidy": this.isOrderWithSubsidy,
    }
  }
}

export function mapCustomers(manager) {
  return new CustomerModel(
    manager.data(),
    manager.ref,
  )
}
