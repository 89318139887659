export default class CounterModel {
  count: number | null
  actualCount: number | null
  tomorrowCount: number | null

  constructor(data) {
    this.count = data?.count === 0
      ? data?.count
      : data?.count || null
    this.actualCount = data?.actualCount === 0
      ? data?.actualCount
      : data?.count || null
    this.tomorrowCount = data?.tomorrowCount === 0
      ? data?.tomorrowCount
      : data?.count || null
  }

  toMap() {
    return ({
      "count": this.count,
      "actualCount": this.actualCount,
      "tomorrowCount": this.tomorrowCount,
    })
  }
}
