import type { DocumentReference, FieldValue, Timestamp } from "firebase/firestore"

export class CouponModel {
  isActive: boolean
  codeSupplier: string | null
  companyRef: DocumentReference | null
  disableAfterFirstUse: boolean | null
  endDate: Timestamp | null
  fixedSalesAmount: number | null
  maxOrderPrice: number | null
  minOrderPrice: number | null
  phone: string | null
  promoCode: string | null
  salesPercentage: number | null
  useOnlyOnDay: Timestamp | null
  createdByUser: DocumentReference | null
  lastUpdateTime: FieldValue | null
  lastUpdateByUser: DocumentReference | null
  reference: DocumentReference | null

  constructor(data, reference?) {
    this.isActive = data.isActive || true
    this.codeSupplier = data.codeSupplier || null
    this.companyRef = data.companyRef || null
    this.disableAfterFirstUse = data.disableAfterFirstUse || false
    this.endDate = data.endDate || null
    this.fixedSalesAmount = Number(data.fixedSalesAmount) || null
    this.isActive = data.isActive || false
    this.maxOrderPrice = Number(data.maxOrderPrice) || null
    this.minOrderPrice = Number(data.minOrderPrice) || null
    this.phone = data.phone || null
    this.promoCode = data.promoCode || null
    this.salesPercentage = data.salesPercentage || null
    this.useOnlyOnDay = data.useOnlyOnDay || null

    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null

    this.reference = reference
  }

  toMap() {
    return {
      "isActive": this.isActive,
      "codeSupplier": this.codeSupplier,
      "companyRef": this.companyRef,
      "disableAfterFirstUse": this.disableAfterFirstUse,
      "endDate": this.endDate,
      "fixedSalesAmount": this.fixedSalesAmount,
      "maxOrderPrice": this.maxOrderPrice,
      "minOrderPrice": this.minOrderPrice,
      "phone": this.phone,
      "promoCode": this.promoCode,
      "salesPercentage": this.salesPercentage,
      "useOnlyOnDay": this.useOnlyOnDay,

      "lastUpdateTime": this.lastUpdateTime || null,
      "lastUpdateByUser": this.lastUpdateByUser || null,
    }
  }
}

export function mapCoupons(data) {
  return new CouponModel(
    data.data(),
    data.ref,
  )
}
