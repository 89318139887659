import { getDocs } from "firebase/firestore"
import { errorDefault } from "../helpers/snackbar"
import {
  getCollectionOrdersStatistic,
} from "../firestoreWrappers"
import { mapOrdersStatistic } from "../models/OrdersStatisticModel"
import store from "~/store"

function initialState() {
  return {
    "data": null,
    "loading": false,
    "error": null,
  }
}

export default {
  "namespaced": true,
  "state": initialState(),
  "actions": {
    resetState({ commit }) {
      commit("RESET_STATE")
    },

    getOnlineTransactionReports({
      commit,
      "rootState": {
        "app": {
          "userData": {
            company,
          },
        },
      },
    }, {
      startDate,
      endDate,
      date,
      city,
    }) {
      commit("GET_ONLINE_TRANSACTION_REPORTS_REQUEST")
      const onSuccess = ({ docs }) => {
        commit(
          "GET_ONLINE_TRANSACTION_REPORTS_SUCCESS",
          {
            "data": docs.map(mapOrdersStatistic),
            date,
            city,
          },
        )
      }
      const onError = (error) => {
        console.error(error)
        commit(
          "GET_ONLINE_TRANSACTION_REPORTS_FAILURE",
          error,
        )
        store.dispatch(
          "snackbar/showSnackbar",
          errorDefault(error),
        )
      }

      getDocs(getCollectionOrdersStatistic(
        company,
        startDate,
        endDate,
        city,
      ))
        .then(onSuccess)
        .catch(onError)
    },
  },
  "mutations": {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    GET_ONLINE_TRANSACTION_REPORTS_REQUEST(_state) {
      _state.error = null
      _state.loading = true
    },
    GET_ONLINE_TRANSACTION_REPORTS_SUCCESS(_state, { data, date, city }) {
      const copy = { ...(_state?.data || {}) } || {}
      copy[city] = _state?.data?.[city] || {}
      copy[city][date] = data

      _state.data = copy
      _state.error = null
      _state.loading = false
    },
    GET_ONLINE_TRANSACTION_REPORTS_FAILURE(_state, error) {
      _state.error = error
      _state.loading = false
    },
  },
}
