import { addDoc, deleteDoc, serverTimestamp, updateDoc } from "firebase/firestore"
import { errorDefault, savedDefault } from "../helpers/snackbar"
import { getIdFromRef } from "../helpers/getIdFromRef"
import { mapData } from "../models/InvoiceModel"
import { collectionInvoices, getInvoicesCollection } from "../firebase/firestoreInvoices"
import store from "."

function onErrorShared(error) {
  console.error(error)
  store.dispatch(
    "snackbar/showSnackbar",
    errorDefault(error),
  )
}

function initialState() {
  return {
    "data": null,
  }
}

export default {
  "namespaced": true,
  "state": initialState(),
  "actions": {
    resetState({ commit }) {
      commit("RESET_STATE")
    },

    getInvoices({
      commit,
      "rootState": {
        "app": {
          "userData": { company },
        },
      },
    }) {
      commit("GET_REQUEST")
      store.dispatch("shared/init")

      const onSuccess = ({ docs }) => {
        commit(
          "GET_SUCCESS",
          docs.map(mapData),
        )
        store.dispatch("shared/success")
      }

      const onError = (error) => {
        console.error(error)
        commit(
          "GET_FAILURE",
          error,
        )
        store.dispatch(
          "snackbar/showSnackbar",
          errorDefault(error),
        )
      }

      getInvoicesCollection(company)
        .then(onSuccess)
        .catch(onError)
    },
    async createInvoice(
      {
        commit,
        "rootState": {
          "app": {
            userData,
          },
        },
      },
      data,
    ) {
      commit("CREATE_REQUEST")
      let newInvoiceRef

      try {
        data.lastUpdateByUser = userData.reference
        data.lastUpdateTime = serverTimestamp()

        newInvoiceRef = await addDoc(
          collectionInvoices,
          {
            ...data.toMap(),
            "createdByUser": userData.reference,
          },
        )

        data.reference = newInvoiceRef

        commit(
          "CREATE_SUCCESS",
          data,
        )

        store.dispatch(
          "snackbar/showSnackbar",
          savedDefault,
        )
      }
      catch (error) {
        if (newInvoiceRef)
          await deleteDoc(newInvoiceRef)

        commit(
          "CREATE_FAILURE",
          error,
        )
        onErrorShared(error)
      }
    },
    editInvoice({
      commit,
      "rootState": {
        "app": { userData },
        "invoices": { data },
      },
    }, newData) {
      commit("UPDATE_REQUEST")

      const onError = (error) => {
        console.error(error)
        commit(
          "UPDATE_FAILURE",
          error,
        )
        onErrorShared(error)
      }

      const onSuccess = () => {
        const newInvoice = data.map((invoice) => {
          if (getIdFromRef(invoice.reference) == getIdFromRef(newData.reference))
            return newData

          return invoice
        })
        commit(
          "UPDATE_SUCCESS",
          newInvoice,
        )
        store.dispatch(
          "snackbar/showSnackbar",
          savedDefault,
        )
      }

      newData.lastUpdateByUser = userData.reference
      newData.lastUpdateTime = serverTimestamp()

      updateDoc(
        newData.reference,
        newData.toMap(),
      )
        .then(onSuccess)
        .catch(onError)
    },
  },
  "mutations": {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },
    GET_REQUEST() {
    },
    GET_SUCCESS(_state, data) {
      _state.data = data
    },
    GET_FAILURE() {
    },
    CREATE_REQUEST() {
    },
    CREATE_SUCCESS(_state, data) {
      _state.data = [
        ...(_state.data || []),
        data,
      ]
    },
    CREATE_FAILURE() {
    },
    UPDATE_REQUEST() {
    },
    UPDATE_SUCCESS(_state, data) {
      _state.data = data
      _state.loading = false
      _state.error = null
    },
    UPDATE_FAILURE() {
    },
  },
}
