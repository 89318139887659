import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import pl from "vuetify/es5/locale/pl"

Vue.use(Vuetify)

const mq = window.matchMedia("(prefers-color-scheme: dark)")
const dark

  = localStorage.dark === undefined
    ? mq.matches
    : JSON.parse(localStorage.dark)

const vuetify = new Vuetify({
  "lang": {
    "locales": { pl },
    "current": "pl",
  },
  "theme": {
    dark,
    "themes": {
      "light": {
        "primary": "#407595",
        "secondary": "#407595",
        "accent": "#407595",
        "darkBlue": "#407595",
        "background": "#FFF",
        "neutral": "#000000",
        "surface": "#F2F2F2",
      },
      "dark": {
        "primary": "#407595",
        "secondary": "#407595",
        "accent": "#407595",
        "darkBlue": "#407595",
        "background": "#121212",
        "neutral": "#ffffff",
        "surface": "#232323",
      },
    },
    "options": {
      "variations": false,
      minifyTheme(css) {
        // @ts-expect-error
        return import.meta.env.NODE_ENV === "production"
          ? css.replace(
            /[\r\n|\r|\n]/g,
            "",
          )
          : css
      },
    },
  },
  "icons": {
    "iconfont": "mdiSvg",
  },
})

vuetify.framework.theme.dark

  = localStorage.dark === undefined
    ? mq.matches
    : JSON.parse(localStorage.dark)
mq.addListener((event) => {
  vuetify.framework.theme.dark

    = localStorage.dark === undefined
      ? event.matches
      : JSON.parse(localStorage.dark)
})

export default vuetify
