import type { DocumentReference, FieldValue } from "firebase/firestore"
import { Earnings } from "./EarningsModel"

export class RouteModel {
  city: string | null
  assignUsers: number | null
  deliver: DocumentReference | null
  deliverFirstName: string | null
  deliverLastName: string | null
  name: string | null
  subDeliver: DocumentReference | null
  subDeliverFirstName: string | null
  subDeliverLastName: string | null
  isSubDeliver: boolean | null
  company: DocumentReference | null
  pendingOrders: number | null
  ordersCount: number | null
  createdByUser: DocumentReference | null
  lastUpdateByUser: DocumentReference | null
  lastUpdateTime: FieldValue | null
  reference: DocumentReference | null
  visibilityAccess: any
  isVisibleEarns: any
  earnings: any

  constructor(data, reference?) {
    this.city = data.city || null
    this.assignUsers = data.assignUsers || 0
    this.deliver = data.deliver || null
    this.deliverFirstName = data.deliverFirstName || null
    this.deliverLastName = data.deliverLastName || null
    this.name = data.name || null
    this.subDeliver = data.subDeliver || null
    this.subDeliverFirstName = data.subDeliverFirstName || null
    this.subDeliverLastName = data.subDeliverLastName || null
    this.isSubDeliver = data.isSubDeliver
    this.company = data.company || null
    this.pendingOrders = data.pendingOrders || 0
    this.ordersCount = data.ordersCount || 0
    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null
    this.visibilityAccess = data.visibilityAccess || []
    this.isVisibleEarns = data.isVisibleEarns || false
    this.earnings = data.earnings
      ? new Earnings(data.earnings)
      : null

    this.reference = reference
  }

  toMap() {
    return {
      "city": this.city,
      "assignUsers": this.assignUsers,
      "deliver": this.deliver,
      "deliverFirstName": this.deliverFirstName?.trim() || null,
      "deliverLastName": this.deliverLastName?.trim() || null,
      "name": this.name?.trim() || null,
      "subDeliver": this.subDeliver,
      "subDeliverFirstName": this.subDeliverFirstName?.trim() || null,
      "subDeliverLastName": this.subDeliverLastName?.trim() || null,
      "isSubDeliver": this.isSubDeliver,
      "ordersCount": this.ordersCount,
      "pendingOrders": this.pendingOrders,
      "company": this.company,
      "visibilityAccess": this.visibilityAccess || [],
      "isVisibleEarns": this.isVisibleEarns || false,
      "earnings": this.earnings ? this.earnings.toMap() : null,
      "lastUpdateTime": this.lastUpdateTime || null,
      "lastUpdateByUser": this.lastUpdateByUser || null,

    }
  }

  toMapFirebase() {
    return {
      "city": this.city,
      "assignUsers": this.assignUsers,
      "deliver": this.deliver,
      "deliverFirstName": this.deliverFirstName?.trim() || null,
      "deliverLastName": this.deliverLastName?.trim() || null,
      "name": this.name?.trim() || null,
      "subDeliver": this.subDeliver,
      "subDeliverFirstName": this.subDeliverFirstName?.trim() || null,
      "subDeliverLastName": this.subDeliverLastName?.trim() || null,
      "isSubDeliver": this.isSubDeliver,
      "company": this.company,
      "visibilityAccess": this.visibilityAccess || [],
      "isVisibleEarns": this.isVisibleEarns || false,
      "earnings": this.earnings ? this.earnings.toMap() : null,

      "lastUpdateTime": this.lastUpdateTime || null,
      "lastUpdateByUser": this.lastUpdateByUser || null,

    }
  }
}

export function mapRoute(route) {
  return new RouteModel(
    route.data(),
    route.ref,
  )
}
